@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css);
#parent-scroll-div .ant-list-item-meta-description {
  margin-top: -5px;
}
#parent-scroll-div .ant-list-item-meta-title {
  margin-top: -1px;
}

.ant-tooltip-inner {
  background-color: #ffcc00 !important;
  color: #333 !important;
}
/* .ant-radio-wrapper {
  display: flex;
  align-items: center;
}

.ant-radio-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-radio-input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #1890ff;
}

.ant-radio-inner:after {
  background-color: #1890ff;
} */




/* custom stylinig for phoneInput placeholder */
.phoneInput::placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5715;
}
.page {
  background-color: white;
  position: relative;
  z-index: 0;
}
.vector {
  position: absolute;
  z-index: 1;
}
.image {
  position: absolute;
  z-index: 2;
}

.withNavSignbutton {
  position: fixed !important;
  bottom: 23%;
  left: 24%;
  background-color: #fffc00 !important;
  color: #f40000 !important;
  padding: 1px 9px !important;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px !important;
  border: none !important;
  z-index: 1;
}

.withoutNavSignbutton {
  position: fixed !important;
  bottom: 23%;
  left: 10%;
  background-color: #fffc00 !important;
  color: #f40000 !important;
  padding: 1px 9px !important;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px !important;
  border: none !important;
  z-index: 1;
}

@media (max-width: 1000px) {
  .withNavSignbutton {
    left: 0%;
  }
  .withoutNavSignbutton {
    left: 0%;
  }
}

.withoutNavSignbutton::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-left: 19px solid #fffc00;
  border-bottom: 19px solid transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -18px;
}

.withNavSignbutton::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-left: 19px solid #fffc00;
  border-bottom: 19px solid transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -18px;
}

input[type="date"] {
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}
/* input[type="time"] */
/* input[type="time"]::-webkit-calendar-picker-indicator  */
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none !important;
}

.ant-table-tbody > tr > td {
  position: relative;
  padding: 8px 8px;
  overflow-wrap: break-word;
}

.zoom_button_1 {
  position: fixed;
  top: 70%;
  right: 1.5%;
  z-index: 11;
}
.zoom-plus-button_1 {
  background-color: white;
  cursor: pointer;
  border-radius: 50%;
  -webkit-user-select: none;
          user-select: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  height: 50px;
  /* opacity: 0.67; */
}

.zoom-minus-button_1 {
  background-color: white;
  margin-top: 15px;
  cursor: pointer;
  border-radius: 50%;
  -webkit-user-select: none;
          user-select: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  height: 50px;
  /* opacity: 0.67; */
}
.zoom-icons {
  width: 50px;
  height: 50px;
}

.zoom-plus-button_1:hover #zoom-plus-color {
  fill: #004aff;
}

.zoom-plus-button_1 #zoom-plus-color {
  fill: #84c1fa;
}


.zoom-minus-button_1:hover #zoom-minus-color {
  fill: #004aff;
}

.zoom-minus-button_1 #zoom-minus-color {
  fill: #84c1fa;
}


/* CSS for mobile devices */
@media (max-width: 768px) {
  .zoom_button_1 {
    /* position: fixed !important;
    height: 20px;
    bottom: 5vh;
    right: 5vw;
    z-index: 161; */
    display: none;
  }
}
.table-skeleton td,
.table-skeleton thead,
.table-skeleton tbody,
.table-skeleton tr,
.table-skeleton {
  border: 1px solid black;
}

.ant-col-12 {
  max-width: inherit !important;
  margin-right: 30px;
}

.check {
  padding: 0px !important;
}

.dots-3 {
  width: 10px;
  margin: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: d5 1s infinite linear alternate;
}
@keyframes d5 {
  0% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #000;
  }
  33% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #0002;
  }
  66% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #0002;
  }
  100% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #000;
  }
  0% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #000;
  }
  33% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #0002;
  }
  66% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #0002;
  }
  100% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #000;
  }
}
/* transaction report css starts */
.tr-property-name {
  background-color: #085191;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}
.tr-container {
  margin: 3px;
  padding: 10px;
  width: 70%;
  margin: auto;
}
.tr-heading {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}
.tr-buyers-container {
  margin-top: 1%;
  border-radius: 15px;
  font-size: 16px;
}
.tr-sellers-container,
.tr-agency-container,
.tr-documents-container,
.tr-dates-container {
  margin-top: 1%;
  margin-bottom: 3%;
  border-radius: 15px;
  font-size: 16px;
}
.tr-sellers-container,
.tr-agency-container,
.tr-buyers-container {
  padding-left: 20px;
}
.tr-sellers-container {
  padding-left: 30px !important;
}
@media print {
  .tr-sellers-container,
  .tr-agency-container,
  .tr-buyers-container {
    padding-left: 0px;
  }
  .tr-sellers-container {
    padding-left: 0px !important;
  }
  .tr-container {
    margin: 3px;
    padding: 10px;
  }
}
.tr-sellers-container > table,
.tr-agency-container > table,
.tr-documents-container > table,
.tr-dates-container > table {
  width: 980px;
}
.tr-container table td,
.tr-container table th {
  padding-left: 5px;
  border: 1px solid black;
}
.tr-container table thead {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.tr-sellers-section,
.tr-agency-section {
  margin-top: 10px;
}
.tr-a-buyer {
  margin: 1%;
  border-radius: 15px;
  width: 480px;
}
.tr-a-buyer > div,
.tr-a-seller > div,
.tr-listing-co > div,
.tr-selling-co > div {
  text-align: left;
  margin-left: 3%;
}
.tr-a-seller,
.tr-listing-co,
.tr-selling-co {
  margin: 1%;
  border-radius: 15px;
  width: 480px;
}
.tr-a-seller > div {
  margin: 1%;
}
.fields-tr {
  height: 27px;
  width: 140px;
}
.field-values-tr {
  margin-left: 3%;
  width: 350px;
  height: 27px;
  color: #004aff !important;
  font-weight: 500;
  /* border-bottom: 2px solid black; */
}
.rtd-table-tr,
.rtd-table-tr-sd {
  width: 70%;
  margin: auto;
  padding: 20px 0px 20px;
}
.rtd-table-tr tr,
.rtd-table-tr-sd tr {
  height: 50px;
}
.rtd-table-tr thead,
.rtd-table-tr-sd thead {
  background-color: #085191;
  color: white;
}
.rtd-table-tr tr > td:nth-child(1) {
  width: 100px;
}
.rtd-table-tr tr > td:nth-child(2) {
  width: 500px;
}
.rtd-table-tr tr > td:nth-child(3) {
  width: 100px;
}
.rtd-table-tr tr > td:nth-child(4) {
  width: 100px;
}

h3.ant-typography,
.ant-typography h3 {
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 22px !important;
  text-transform: uppercase !important;
}

.lbl1 label {
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}




/* *******new form css************* */
#rpanrResize {
  display: flex;
  width: 100%;
  height: calc(100vh - 00px);
  flex-grow: 0;
  flex-shrink: 0;
  background: #7d7d80;
  width: 0.5rem;
  cursor: col-resize;
}
.reSizerWrapper {
  display: flex;
  width: 100%;
  height: calc(100vh - 00px);
}

#pan1 {
  display: flex;
  width: 100%;
  height: calc(100vh - 00px);
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 25%; /* initial status */
  overflow: hidden;
  padding-top: 10vh;
}
#pan2 {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 1;
  overflow-x: auto;
  margin-top: 10vh;
}
#rpanrResize .CCVOpenCloseBtn {
  transform: rotate(-90deg);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #124E8F;
  color: #fff;
  font-size: 20px;
  border-end-end-radius: 10px;
  border-end-start-radius: 10px;
  border: none;
  font-weight: 500;
  position: relative;
  z-index: 10;
  left: -20px;
}

.commonModalTitle{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  color: #2697FF;
  text-transform: capitalize;
}


/* Media query for screens above 1000px width */
@media screen and (min-width: 1001px) {
  #rpanrResize {
    flex-grow: 0;
    flex-shrink: 0;
    width: 0.5rem;
    cursor: col-resize;
  }
  .reSizerWrapper {
    display: flex;
    width: 100%;
  }

  #pan2 {
    display: flex;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 1;
    overflow-x: auto;
    justify-content: center;
  }
}

/* Media query for screens below 1000px width */
@media screen and (max-width: 1000px) {
  #pan1 {
    display: flex;
    width: 100%;
    /* height: calc(100vh - 00px); */
    flex-grow: 1;
    flex-shrink: 2;
    flex-basis: 0%; /* initial status */
    overflow: hidden;
  }
  #rpanrResize {
    display: flex;
    border: none;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    background: #fff;
    width: 0.5rem;
    cursor: col-resize;
  }
  
}

@media screen and (max-width: 500px) {
  #pan1 {
    flex-shrink: 0;
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
    visibility: hidden;
    z-index: 2;
  }
  @keyframes sidebar-load {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);

    }
  }

  @keyframes sidebar-unload {

    0% {
        transform: translateX(0);

    }

    100% {
        transform: translateX(-100%);
    }
  }

  #rpanrResize .CCVOpenCloseBtn {
    font-size: 14px;
    border-end-end-radius: 8px;
    border-end-start-radius: 8px;
    font-weight: 700;
    left: -20px;
  }
  #pan2 {
    margin-top: 8vh;
  }
  
  .commonModalTitle{
    font-size: 18px;
  }
}


@media print {
  html, body {
    background-color: #ffffff;
    font-size: 10pt !important;
  }
  @page {
    size: letter;
  }

  .no-print, nav, #pan1 {
    visibility: hidden;
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  div:not(.signature-block):not(.no-block),
  .print {
    display: block !important;
    max-width: 100% !important;
    width: 100% !important;
    padding-top: 3px !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    overflow-y: visible !important;
  }

  span[contenteditable],
  input,
  textarea {
    background-color: white !important;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none !important;
  }

  #pan2 {
    margin: 0 !important;
    padding: 0 !important;
  }

  #AGENT_HEADER {
    /* display: flex !important;
    gap: 20px;
    align-items: center; */
    margin-bottom: 10px;
  }
  .form-wrapper .titleOrderForm .titleCompanyLogoAndName,
  .form-wrapper .titleOrderForm .titleCompanyDetailContainer .titleCompanyAddressTable,
  .form-wrapper .titleOrderForm .titleCompanyDetailContainer .DoubleSidedTransactionContainer {
    display: flex !important;
  }

  .form-wrapper .titleOrderForm .titleCompanyLogoAndName {
    margin-bottom: 10px !important;
  }

  .form-wrapper .titleOrderForm .titleCompanyDetailContainer .titleCompanyAddressTable {
    zoom: 0.9;
  }
  .form-from-html{
    font-family: Arial, Helvetica, sans-serif, 'Times New Roman' !important;
  }
  .form-from-html .form-wrapper .mt {
    margin-top: 1rem !important;
  }

  .form-from-html .form-wrapper .mt2 {
    margin-top: 2rem !important;
  }
  .form-from-html .form-wrapper .signature-block {
    page-break-inside: avoid;
  }

  .form-wrapper .SeparatorVerticalLine {
    display: none !important;
    visibility: hidden !important;
  }
}


.form-from-html {
  overflow-x: hidden;
  
  /* FOR IOS MAKING CSS COMPATIBLE */
  /* -webkit-text-size-adjust: 0; */
  /* -webkit-text-size-adjust: auto; */
  -webkit-text-size-adjust: initial;
}

.form-from-html body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form-from-html{
  font-family: Arial, Helvetica, sans-serif, 'Times New Roman' !important;
  font-size: 10pt !important;
}

/* ************************title order css starts********************** */

.form-wrapper .titleOrderForm .sectionTitle {
  background-color: #B4D4FF;
  padding: 5px 0;
}

.form-wrapper .titleOrderForm .titleCompanyLogoAndName {
  display: flex;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
  align-items: center;
}

/* .form-wrapper .titleOrderForm .titleCompanyLogoAndName .logoContainer {
  width: 100%;
  text-align: center;
} */

.form-wrapper .titleOrderForm .titleCompanyLogoAndName .logoContainer,
.form-wrapper #transactionReport .CompanyAddressTable .logoContainer {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: end;
}

.form-wrapper .titleOrderForm .titleCompanyLogoAndName .logoContainer .TitleCompanyLogo {
  width: 30%
}

.form-wrapper #transactionReport .CompanyAddressTable .logoContainer .agentCompanyLogo {
  min-width: 60px;
  max-width: 100px;
}

.form-wrapper .titleOrderForm .titleCompanyLogoAndName .nameContainer {
  width: 100%;
  text-align: center;
  font-size: x-large;
}
.form-wrapper .titleOrderForm .titleCompanyLogoAndName .dummyContainer {
  width: 100%;
}
.form-wrapper .titleOrderForm .titleCompanyLogoAndName .nameContainer input {
  color: inherit;
  background-color: inherit;
}
.form-wrapper .titleOrderForm .titleCompanyLogoAndName .nameContainer input:focus {
  outline: none;
}
.form-wrapper .titleOrderForm .titleCompanyLogoAndName .nameContainer input::placeholder {
  font-size: inherit;
}

.form-wrapper .titleOrderForm .titleCompanyDetailContainer .titleCompanyAddressTable {
  display: flex;
  justify-content: space-between;
  align-items: start;
  grid-gap: 30px;
  gap: 30px;
}

.form-wrapper .titleOrderForm .titleCompanyDetailContainer .titleCompanyAddressTable.titleCompanyDetailTable input,
.form-wrapper .titleOrderForm .titleCompanyDetailContainer .titleCompanyAddressTable.titleCompanyDetailTable textarea {
  background-color: #f0f7fd;
  color: blue;
}

.form-wrapper .titleOrderForm .titleCompanyDetailContainer .titleCompanyAddressTable .rightTable,
.form-wrapper #transactionReport .CompanyAddressTable .rightTable {
  width: 25%;
  /* width: 30%; */
}
.form-wrapper .titleOrderForm .titleCompanyDetailContainer .titleCompanyAddressTable .leftTable,
.form-wrapper #transactionReport .CompanyAddressTable .leftTable {
  width: 25%;
  /* width: 30%; */
}
.form-wrapper .titleOrderForm .titleCompanyDetailContainer .titleCompanyAddressTable .listingTableContainer,
.form-wrapper .titleOrderForm .titleCompanyDetailContainer .titleCompanyAddressTable .sellingTableContainer {
  width: 100%;
}

.form-wrapper .titleOrderForm .titleCompanyDetailContainer .titleCompanyAddressTable .listingSellingSeparatorVerticalLine {
  position: absolute;
  border-left: 1px solid;
  /* color: orange; */
  color: #B4D4FF;
  left: 50%;
  top: 0;
  height: 100%;
}

.form-wrapper .titleOrderForm .tableWidth {
  width: auto;
  display: inline;
}

.form-wrapper .titleOrderForm .tableWidth2 {
  width: 100%;
  /* display: inline; */
  /* outline: auto; */
}

.form-wrapper .titleOrderForm .tableWidth3,
.form-wrapper .titleOrderForm .tableWidth3 td,
.form-wrapper #transactionReport .tableWidth3,
.form-wrapper #transactionReport .tableWidth3 td,
.form-wrapper #transactionReport .tableWidth3 th
 {
  border: 1px solid;
  border-collapse: collapse;
  border-color: silver;
}

.form-wrapper .titleOrderForm input:not(.noWidth,input[type='checkbox']),
.form-wrapper .titleOrderForm textarea,
.form-wrapper #transactionReport input:not(.noWidth,input[type='checkbox']),
.form-wrapper #transactionReport textarea {
  width: 100%;
}

.form-wrapper .titleOrderForm input::placeholder,
.form-wrapper .titleOrderForm textarea::placeholder,
.form-wrapper #transactionReport input::placeholder,
.form-wrapper #transactionReport textarea::placeholder {
  font-weight: 100;
  font-size: small;
  color: rgb(170, 168, 168);
}

.form-wrapper .titleOrderForm #titleCompanySection .nbb{
  border: 0 !important;
}

/* ************************title order css ends********************** */

/* ************************Transaction report css starts********************** */

.form-wrapper #transactionReport .sectionTitle {
  background-color: #B4D4FF;
  padding: 5px 0;
}
.form-wrapper #transactionReport .CompanyAddressTable {
  display: flex;
  justify-content: space-between;
  align-items: start;
  grid-gap: 30px;
  gap: 30px;
}

.form-wrapper .SeparatorVerticalLine {
  position: absolute;
  border-left: 1px solid;
  /* color: orange; */
  color: #B4D4FF;
  left: 50%;
  top: 0;
  height: 100%;
}
.form-wrapper #transactionReport .CompanyAddressTable .listingTableContainer,
.form-wrapper #transactionReport .CompanyAddressTable .sellingTableContainer {
  width: 100%;
}

.form-wrapper #transactionReport #emailCommunicationsSection .list-container {
  display: none;
}

.form-wrapper #transactionReport #emailCommunicationsSection .dropdown-icon {
  margin-left: 20px;
  text-align: left;
  display: inline-block;
}
.form-wrapper #transactionReport #emailCommunicationsSection .dropDownContent:hover {
  background-color: #95d3ce;
}
.form-wrapper #transactionReport #emailCommunicationsSection .subjectColumn {
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Display ellipsis for overflow */
  max-width: 0; /* So that td width should not increase */
  min-width: 200px;
}
.form-wrapper #transactionReport #emailCommunicationsSection .expanded .subjectColumn {
  white-space: normal; /* Override white-space to display full text */
  overflow: visible; /* Override overflow */
  text-overflow: initial; /* Override text-overflow */
  max-width: 0; /* So that td width should not increase */
  word-wrap: break-word;
}
.form-wrapper #transactionReport textarea:disabled,
.form-wrapper #transactionReport input[type='date']:disabled {
  background-color: #ececec;
  color: black;
}
.form-wrapper #transactionReport .nbb{
  border: 0 !important;
}
.form-wrapper #transactionReport .noBg{
  background-color: inherit;
  color: inherit;
}



/* ************************Transaction report css ends********************** */

.form-from-html .form-wrapper {
  /* margin: 2rem 2em; */
  padding: 2rem 2rem;
  /* padding: 32px 32px; */
  overflow-x: auto;
  width: -webkit-fill-available;
  max-width: 1000px;
  background-color: white;
  margin: auto;
}

input.noAutoSave::placeholder {
  color: rgb(170, 168, 168);
}

.form-wrapper .clickable:hover {
  cursor: pointer;
}


.form-wrapper .bg-grey {
  background-color: lightgray;
}

.form-wrapper .double-border {
  padding: 0.4rem 0;
  /* padding: 6px 0; */
  border-width: 5px;
  border-style: double;
}

.form-wrapper .fssm {
  font-size: 0.9em;
  /* font-size: 14px; */
}

.form-wrapper .fslg {
  font-size: 1.2em;
  /* font-size: 18px; */
}
.form-wrapper .fsxlg {
  font-size: 1.5em;
}

.form-wrapper .removed {
  text-decoration: line-through;
  display: none !important;
}

.form-wrapper input[type="checkbox"] {
  margin: 0px 5px;
  cursor: context-menu !important;
}

.form-wrapper input[type="number"].currency,
.form-wrapper input[type="text"].currency {
  /* min-width: 90%; */
  background-color: #f0f7fd;
}

.form-wrapper input[type="number"]:disabled,
.form-wrapper input[type="text"]:disabled {
  background-color: #ececec;
}

.form-wrapper input.no-edit {
  background-color: white !important;
}

.form-wrapper input[type="date"],
.form-wrapper input[type="time"],
.form-wrapper input[type="number"],
.form-wrapper input[type="email"],
.form-wrapper input[type="text"] {
  border: 0;
  border-bottom: 1px solid black !important;
  font-family: inherit;
  font-size: inherit;
  background-color: #f0f7fd;
  font-weight: bold;
  color: blue;
  cursor: text !important;
}
.form-wrapper textarea {
  cursor: text !important;
}
i {
  text-indent: 0;
}

.form-wrapper span[contenteditable] {
  -webkit-text-decoration: underline black;
          text-decoration: underline black;
  background-color: #f0f7fd;
  color: blue;
  font-weight: bold;
  display: inline;
  text-indent: 0;
}

.form-wrapper span[contenteditable]:empty,
.form-wrapper span[contenteditable]:focus {
  display: inline-block;
}

.form-wrapper span[contenteditable="false"]:empty {
  display: inline-block;
  background-color: #ececec;
  min-height: 1em;
  /* min-height: 16px; */
}

.form-wrapper span[contenteditable]:empty:not(.noul) {
  border-bottom: 1px solid black;
}

.form-wrapper span[contenteditable].ta-like {
  /* min-width: 95%; */
  min-width: 950px;
}

.form-wrapper span[contenteditable].ta-like:focus {
  min-height: 3em;
  /* min-height: 48px; */
}

.form-wrapper span[contenteditable].ta-like:empty:not(.noul) {
  min-height: 3em;
  /* min-height: 48px; */
  border-bottom: 1px solid black;
}

.form-wrapper .noul {
  text-decoration: none !important;
}

.form-wrapper .border-bottom-input,
.form-wrapper input[type="text"]:not(.nb),
.form-wrapper input[type="date"]:not(.nb),
.form-wrapper input[type="time"]:not(.nb),
.form-wrapper input[type="number"]:not(.nb) {
  border: 0;
  border-bottom: 1px solid black !important;
}

.form-wrapper .border-around {
  border: 1px solid black;
  padding: 2px;
}

.form-wrapper .nb {
  border: 0 ;
}

.form-wrapper .is {
  text-indent: 20pt;
  /* text-indent: 26px; */
}

.form-wrapper .id {
  text-indent: 48pt;
  /* text-indent: 64px; */
}

.form-wrapper .it {
  text-indent: 60pt;
  /* text-indent: 80px; */
}

.form-wrapper .il {
  text-indent: 95pt;
}

.form-wrapper .tc {
  text-align: center;
}

.form-wrapper .tl {
  text-align: left;
}

.form-wrapper .tr {
  text-align: right;
}

.form-wrapper .tb {
  font-weight: bold !important;
}

.form-wrapper .ti {
  font-style: italic;
}

.form-wrapper .ua {
  font-weight: bold !important;
  color: blue !important;
}

.form-wrapper .relativeDateInput, .fw{
  font-weight: bold;
  color: blue;
  background-color: #f0f7fd;
  width: -webkit-fill-available;
}

.form-wrapper table.wb input.currency[type="text"] {
  text-align: right;
  min-width: 100px;
}

/* .form-wrapper input.currency[type="text"] {
  text-align: right;
  padding-right: 2px;
  min-width: 100px;
} */

.form-wrapper .tu {
  text-decoration: underline;
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
}

.form-wrapper .mt {
  margin-top: 1rem;
  /* margin-top: 16px; */
}
.form-wrapper .mt2 {
  margin-top: 2rem;
  /* margin-top: 32px; */
}
.form-wrapper .mt3 {
  margin-top: 3rem;
  /* margin-top: 48px; */
}
.form-wrapper .mb {
  margin-bottom: 1rem;
  /* margin-bottom: 16px; */
}
.form-wrapper .ml {
  margin-left: 1rem;
  /* margin-left: 16px; */
}
.form-wrapper .mr {
  margin-right: 1rem;
  /* margin-right: 16px; */
}
.form-wrapper .vh {
  visibility: hidden;
}
.form-wrapper .w100 {
  width: 100%;
}
.form-wrapper .w95 {
  width: 95%;
}
.form-wrapper .bi-pencil-square, .bi-trash3-fill, .bi-trash3 {
 margin-left: 5px;
}
/* .form-wrapper .page-break {
  page-break-before: always;
} */
.form-wrapper .mwfull:empty,
.form-wrapper .mwfull:focus {
  width: 100% !important;
}
.form-wrapper .bdi:empty,
.form-wrapper .mwfull:focus {
 border: 0 !important;
 /* border-bottom: 0px solid red !important; */
 /* background: #fff; */
}

.form-wrapper .mwxl:empty,
.form-wrapper .mwxl:focus {
  min-width: 300px !important;
}

.form-wrapper .mwlg:empty,
.form-wrapper .mwlg:focus {
  min-width: 200px !important;
}

.form-wrapper .mwmd:empty,
.form-wrapper .mwmd:focus {
  min-width: 100px !important;
}

.form-wrapper .mwsm:empty,
.form-wrapper .mwsm:focus {
  min-width: 25px !important;
}

.form-wrapper table.wb,
.form-wrapper table.wb th,
.form-wrapper table.wb td {
  border: 1px solid black;
  border-collapse: collapse;
}

.form-wrapper table {
  width: 100%;
}

.form-wrapper td,
.form-wrapper th {
  padding-left: 2pt;
  padding-right: 2pt;
  /* padding-left: 3px; */
  /* padding-right: 3px; */
}

.form-wrapper table.address {
  border: none !important;
}


.form-wrapper .remove-section {
  color: red;
}

/* [data-signature-block] div:nth-child(odd) {
  width: 45%;
  margin-right: 4.5%;
}

[data-signature-block] div:nth-child(even) {
  width: 45%;
  margin-left: 4.5%;
} */

.form-wrapper [data-signature-block] td {
  border: 0;
}

.form-wrapper .grow-with-content {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}

.form-wrapper .grow-with-input {
  display: inline-grid;
  text-indent: 0;
}

.form-wrapper .grow-with-content::after,
.form-wrapper .grow-with-input::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";
  /* This is how textarea text behaves */
  white-space: pre-wrap;
  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}

.form-wrapper .grow-with-content > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;
  cursor: text;
  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.form-wrapper .grow-with-content > textarea,
.form-wrapper .grow-with-content::after {
  /* Identical styling required!! */
  border: 0;
  padding: 0.25em;
  /* padding: 4px; */
  background-color: #f0f7fd;
  font-weight: bold;
  color: blue;
  font-size: inherit;
  font-family: inherit;
  page-break-inside: avoid;
  break-inside: avoid;

  grid-area: 1 / 1 / 1 / 1;
}

.form-wrapper .grow-with-content > textarea:not(.nm),
.form-wrapper .grow-with-content:not(.nm)::after {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  /* margin-top: 8px;
  margin-bottom: 8px; */
  border: 1px solid black;
}

.form-wrapper .grow-with-input > input,
.form-wrapper .grow-with-input::after {
  /* Identical styling required!! */
  background-color: #f0f7fd;
  font-weight: bold;
  color: blue;
  white-space: pre-wrap;
  font-size: inherit;
  font-family: inherit;
  /* Place on top of each other */
  grid-area: 1 / 1 / 1 / 1;
  padding-right: 0;
  padding-left: 0;
  margin-left: 5px;
}

.form-wrapper #AGENT_HEADER {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}

.form-wrapper #agentOfficeLogo {
  margin: 5px 10px 10px 5px;
}

.form-from-html .nav .section {
  margin: 10px 10px 10px 15px;
  font-size: 1.2em;
  /* font-size: 16px; */
  font-weight: 400;
  /* font-family: sans-serif; */
  /* padding: 5px 5px; */
  /* line-height: 30px; */
  padding: 0.3em;
  line-height: 1.1em;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-feature-settings: "smcp", "c2sc";
  font-variant: all-small-caps;
  background: rgb(18, 78, 143);
  border: 2px solid rgb(40, 119, 196);
  border-radius: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.form-wrapper .signature-wrapper {
  height: 60px;
  display: inline-flex;
  border-radius: 5px;
  background-color: #b6dfdc;
}

.form-wrapper .signature-img,
.form-wrapper .signature-verification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem;
  /* padding: 12px; */
}

.form-wrapper .signature-img img {
  max-width: 250px;
  max-height: 55px;
}

.form-wrapper .signature-verification {
  background-color: #95d3ce;
  color: darkblue;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.form-wrapper .laol {
  padding-inline-start: 1em;
  /* padding-inline-start: 16px; */
}

.form-wrapper .laol li {
  /* margin-top: 4px;
  padding-inline-start: 16px; */
  margin-top: 0.25em;
  padding-inline-start: 1em;
}

.form-wrapper .bb1 {
  border-bottom: 1px solid;
}

.form-wrapper .bb3 {
  border-bottom: 3px solid black;
}

.form-wrapper .active-signer {
  background-color: cyan;
  height: 50px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.form-wrapper .inactive-signer {
  background-color: #cdcdcd;
  height: 50px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-wrapper .active-signer::after {
  content: "Sign Here";
  font-size: 24px;
}

.form-wrapper .inactive-signer::after {
  content: "";
  font-size: 24px;
}

.strikeThrough {
  background: repeating-linear-gradient( 180deg, red 0%, red 100% );
  background-size: 100% 2px;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 10;
}

.form-wrapper #sellerBrokerNameEditIcon {
  color: blue;
  position: absolute;
  top: 3px;
  left: -20px;
  display: none;
}
.form-wrapper .add-broker-btn {
  display: inline-block;
    /* padding: 10px 20px; */
    padding: 5px 10px;
    margin: 10px 0;
    font-size: 16px;
    color: #fff;
    /* background-color: #007bff; */
    background-color: #1890ff;
    /* border-color: #1890ff; */
    border: none;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.form-wrapper .add-broker-btn:hover {
  background-color: #0056b3;
}

.form-wrapper .add-broker-btn:active {
  background-color: #004085;
}

.form-wrapper .grow-with-input > input[type="number"] {
  -webkit-appearance: none !important;
          appearance: none !important;
  -moz-appearance: textfield !important;
}

.form-wrapper .grow-with-input > input[type="number"]::-webkit-outer-spin-button,
.form-wrapper .grow-with-input > input[type="number"]::-webkit-inner-spin-button {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
}

@media screen and (min-width: 1001px) {
  .withoutNav .form-wrapper {
    width: 80%;
    background-color: white;
  }
}
@media screen and (max-width: 1000px) and (min-width: 501px) {
  .form-wrapper {
    zoom: 0.8;
    width: 100%;
  }
  .withoutNav .form-wrapper {
    background-color: white;
  }
  .form-wrapper .active-signer::after {
    content: "Sign Here";
    font-size: 20px;
    white-space: nowrap;
  }
}

@media screen and (max-width: 500px) {
  .form-wrapper {
    zoom: 0.4;
    width: 100%;
    min-width: 600px;
    overflow: hidden;
  }
  .nav {
    /* font-size: 10px; */
    /* margin: 10px 0px; */
    font-size: 0.6rem;
    margin: 0.6rem 0px;
  }
  .withoutNav .form-wrapper {
    background-color: white;
  }
  .form-wrapper .clauseDiv {
    /* margin-bottom: 32px; */
    margin-bottom: 2rem;
  }
  .form-wrapper .active-signer::after {
    content: "Sign Here";
    font-size: 16px;
    white-space: nowrap;
  }
  #pan1 {
    visibility: hidden;
  }
}

.Error_errorMessage__2WTEk {
  font-size: 26px;
  font-weight: semi-bold;
  margin-bottom: 0px;
  max-width: 70vw;
  margin: auto;
}
@media only screen and (max-device-width: 480px) {
  .Error_errorMessage__2WTEk {
    font-size: 15px;
    font-weight: semi-bold;
  }
}

.Loading_spin__189cK {
  display: flex;
  justify-content: center;
  padding: 30px 50px;
  border-radius: 4px;
  height: 100vh;
  align-items: center;
}

#templateNameDiv {
  width: 80%;
  margin: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  text-align: left;
  /* border: 2px solid red; */
}
#templateNameDiv > input {
  padding: 5px 10px;
  /* border-radius: 10px; */
}
#templateDescriptionDiv {
  width: 80%;
  margin: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  text-align: left;
  grid-gap: 10px;
  gap: 10px;
  height: 100px;

  /* border: 2px solid red; */
}
#templateDescriptionDiv > input {
  padding: 10px;
  border-radius: 10px;
}

.ant-dropdown {
  position: fixed !important;
  /* top: 38px; */
}

.navOptions {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}


@media screen and (max-width:1000px) and (min-width:501px) {
  .navOptions {
    font-size: small;
  }
}

@media screen and (max-width:500px) {
  .navOptions {
    font-size: 10px;
  }
 
}

.custom-spinner .ant-spin-dot {
 background-color: transparent; 
}

.custom-spinner .ant-spin-dot-item {
  background-color: greenyellow; 
}

.customForm .ant-input::placeholder {
  color: #69a3f5 !important; /* Custom blue placeholder color */
}
.ant-checkbox-wrapper .ant-checkbox-inner {
  border-color: gray;
    /* Change border color to red */
}
@media (min-width: 601px) and (max-width: 1024px) {
  .ant-checkbox-wrapper .ant-checkbox-inner {
      font-size: 14px; /* Adjust font size */
      width: 16px; /* Adjust checkbox size */
      height: 16px; /* Adjust checkbox size */
  }
}
@media (max-width: 600px) {
.ant-checkbox-wrapper .ant-checkbox-inner {
  font-size: 12px; /* Adjust font size */
  width: 12px; /* Adjust checkbox size */
  height: 12px;
   /* Adjust checkbox size */
}
.customForm .ant-input::placeholder {
font-size: small;
}
}
.OfferTable_licenseTable__2Orjo {
  width: 100%;
  margin: 0px auto 20px auto;
}

.OfferTable_licenseTable__2Orjo tr>th {
  background-color: #2171b9;
  color: white;
  text-align: center;
}

.OfferTable_licenseTable__2Orjo th:first-child {
  border: none;
  border-radius: 5px 0 0 0px;
}

.OfferTable_licenseTable__2Orjo th:last-child {
  border: none;
  border-radius: 0px 5px 0px 0px;
}

.OfferTable_licenseTable__2Orjo tbody {
  border-radius: 0 0 5px 5px;
}

.OfferTable_licenseTable__2Orjo tr:last-child td:first-child {
  border-bottom-right-radius: 5px;
}

.OfferTable_licenseTable__2Orjo tbody tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

.OfferTable_licenseTable__2Orjo tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}
 .OfferTable_omdheading__Wibf2{
display: flex; align-items: center;
 justify-content: center;
 color:white;
    font-size: 1.8rem
 } 

 .OfferTable_licenseTable__2Orjo td, th {
  border: 1px solid #ddd;
}

.OfferTable_licenseTable__2Orjo th {
  text-align: center;
  padding: 0.25rem 0.5rem;
}
.OfferTable_licenseTable__2Orjo td {
  padding: 0.25rem 0.5rem;
}
.OfferTable_licenseTable__2Orjo tr {
  height: 40px;
}
.OfferTable_offertableheight__16WXF{
  border-top-left-radius:15px;
    overflow:hidden;
  height:74vh;
  
}
.OfferTable_rateAlignment__BgOBA {
  margin: 0 !important;
  font-size: 20px !important;
}
.OfferTable_numberHeader__28sRK{
  align-items: center;
  justify-content:space-between;
  display:flex;
  padding-left:20px;
  padding-right:20px
 }

.OfferTable_singleColOfferTable__1herz{
  width: 50%;
margin:auto
  
}
.OfferTable_doubleColOfferTable__1E-uS{
  width: 70%;
  margin:auto
 }
 .OfferTable_threeColOfferTable__3pY9F{
  width: 80%;
  margin:auto
 }

input[type="text"][disabled] {
  color: black;
}
/* th:first-child {
  border:none;
  border-radius:8px 0 0 0px;

}
th:last-child {
  border:none;
  border-radius:0px 8px 0px 0px;
} */


.OfferTable_rateAlignment__BgOBA {
  margin: 0 !important;
  font-size: 15px !important;
}

.OfferTable_textAlignment__7Qv3l {
  font-size: 20px;
}

.OfferTable_ant-table-tbody__7-MrC > tr > td,
.OfferTable_ant-table__3_0ha tfoot > tr > th,
.OfferTable_ant-table__3_0ha tfoot > tr > td {
  position: relative;
  padding: 8px 8px;
  overflow-wrap: break-word;
}
.OfferTable_offerTableNavbar__2HghH {
  min-width: 100vw;
  height: 14vh;
  box-shadow: 0px 1px 5px 0px #00000040;

  overflow: hidden;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0px 80px;
  /* border: 2px solid green; */
  box-sizing: border-box;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  background: #fff;
}
.OfferTable_tableWidth__1NIjs {

  padding-top:20px;
  z-index: 2;
}

.OfferTable_backButton__3VObK{
  margin-top:-20px;
  margin-left:-30px;
  cursor:pointer}
.OfferTable_toolBarSecondCol__2w6T7 {
  width: 100%;
  display: grid;
  grid-gap:100px;
  gap:100px;
  /* grid-template-columns: 0.5fr 2fr 2fr 0.5fr; */
}
.OfferTable_navbarSection1__BOYkR {
  flex: 1 1; 
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  justify-content: flex-start;

  width:100%
 
}
.OfferTable_navbarSection2__237W2 {
  flex: 1 1; 
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  justify-content: flex-end;
  padding-top: 5px;
  margin-left:-20px;
 
  grid-gap: "20px";
 
  gap: "20px";
   
}
.OfferTable_helpicon__oHcKq{
  text-align:left;
  margin-right:-20px;
  margin-top: -5px;
  
  }
  .OfferTable_menuIcon__R8b0F{
    font-weight: bold;
    font-size: 30px;
    margin-right:-30px ;
    text-align: right;
    margin-top:-5px
  }
  .OfferTable_legend__16Tqu{
    font-size: 25px;
    cursor: pointer;
    font-family:bold;
    padding-top: 5px;
    /* color: black; */
  

  }
.OfferTable_propertyimage__1zZm0{
 width: 100%; 

 height: 100px;
 border-radius:5px;
 color:rgb(251, 249, 249)
}

.OfferTable_prorertyAddress__JlFvD{

font-size: 20px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.6px;
text-align: left;

  max-width:283px;
 
  margin-left: -15px;
  font-weight: 400;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 49px;
  /* line-height: 29.5px; */
 
 
}


.OfferTable_firstColAlignment__1oP1k {
  background-color: #F2F2F2 !important;
  /* box-shadow: 0px 0px 4px 0px #00000040; */

max-width: 250px;
min-width: 250px;
 text-transform: capitalize;
  text-align: center;
position: relative;
border:1px solid lightgray;
border-right: 5px solid #F2F2F2;
  top: 0;
  left: 0;
 
font-size: 20px;
font-weight: 600;
line-height: 24px;
margin-bottom: 16px;

letter-spacing: 0.12999999523162842px;
text-align: center;


}

.OfferTable_otherColAlignment__3ob46 {
 max-width: 270px;
 min-width: 270px;
 height:70px;
  border-left: 5px solid #F2F2F2; /* Add a left border */
  border-right: 5px solid #F2F2F2;
 
  font-weight: 400;
  font-size: 18px;
  
  
  
}
.OfferTable_otherColhiddenAlignment__2_Shf {
  max-width: 270px;
  min-width: 270px;
  height:70px;
   border-left: 5px solid #F2F2F2; /* Add a left border */
   border-right: 5px solid #F2F2F2;
   font-weight: 400;
   font-size: 18px;
   color:darkgray;
   background-color:rgb(251, 249, 249);
 }
.OfferTable_flex__ZVLA_{
  display: flex;
  margin-left: 20px;
  margin-right:-20px;
  align-items: center;
  margin-bottom: 10px;
  
 
}
.OfferTable_offerNumber__1PQE_{
  display:flex;
  margin-top:5px;
  margin-left:-15px;
  background:#71BB66;
  text-align:center;
  justify-content:center;
align-items:center;
  border-radius:5px;
 
  font-size:15px;
  color:white;
  width:127px;
  grid-gap:10px;
  gap:10px;
  box-shadow:0px 1px 5px 0px #00000040;
  height:25px;
  padding:"1px 8px 1px 8px";
  }
  .OfferTable_import__1IPEk{
    font-size: 25px;
    /* cursor: pointer; */
    font-family:bold;
    padding-top: 5px;
    /* color: black; */
  
  }
  .OfferTable_customscrollbar__EkV2w {
    height: 500px;
    overflow: auto;
    scrollbar-color: #2171b9; /* Change the thumb and track color for Firefox */
  }
  
  /* Custom scrollbar style for Firefox */
  .OfferTable_customscrollbar__EkV2w::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
  }
  
  .OfferTable_customscrollbar__EkV2w::-webkit-scrollbar-thumb {
    background: #2171b9; /* Change the thumb color */
  }
  
  .OfferTable_customscrollbar__EkV2w::-webkit-scrollbar-track {
    background: gray; /* Change the track color */
  }
  .OfferTable_legendtext__qBjHj{
    text-decoration: underline;
   cursor: pointer; 
    font-size: 20px; 
    font-weight: 600;
     margin-left: 10px
    
  }
 
.OfferTable_criteria__2jhRj{
  text-transform:capitalize;
  font-weight: 600;
  font-size: 22px;
  text-align: center;
 
  display: flex;
  width:100%;
  justify-content: space-between;
 
    /* align-items: flex-start */
  
}
.OfferTable_offertablenumber__1DObJ{
font-weight: 600;
 display:flex;
  align-items: center;
   font-size: 22px;
   color:#fff;
    margin-top: -5px
}
  .OfferTable_heart__1jnNL{
    animation: OfferTable_grow__3z8GH 2s ease infinite;
  }
  @keyframes  OfferTable_grow__3z8GH {
    from { transform: scale(0); }
    to { transform: scale(1); }
  }
.OfferTable_header__3VCvI{
 width: 100vw; 
 height: 55px;
  background: rgb(8, 81, 145);
    padding: 0px 25px;
    position:-webkit-sticky ;
    position:sticky ;
    top: 0; left: 0;
    z-index:10;
    
}
.OfferTable_checkPoint__3OSkF{
  width: 30px;
   height: 30px;

}
.OfferTable_menuitemicon__10ZNw{
    width: 30px;
    height: 30px;
    font-weight: 600;
    margin-left: 5px;
    font-size: 26px;
    color:  white,
  
}
.OfferTable_headername__Ri8FR {
  color: white;
  font-size: 1.4rem;
  max-width: 300px;
  font-weight: 500;
  white-space: nowrap; /* Corrected */
  text-overflow: ellipsis;
  overflow: hidden;
}
.OfferTable_headerlogo__176yn{
 width:100px;
 height:50px
}
.OfferTable_omdname__3LnwW{
  font-weight: 500;
}

.OfferTable_eyeIcon__125wj:hover {
  color: #178DFA;
}

@media only screen and (max-width: 1366px) {
  .OfferTable_header__3VCvI{
    width: 100vw; 
    height: 45px;
     background: rgb(8, 81, 145);
       padding: 0px 25px;
       position:-webkit-sticky ;
       position:sticky ;
       top: 0; left: 0;
       z-index:10;
       
   }
   .OfferTable_omdname__3LnwW{
    font-weight: 500;
  }
  .OfferTable_headername__Ri8FR {
    color: white;
 
    font-size: 1rem;
    max-width: 300px;
    font-weight: 500;
    white-space: nowrap; /* Corrected */
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .OfferTable_omdheading__Wibf2{
    display: flex; align-items: center;
     justify-content: center;
     color:white;
        font-size: 1.2rem;

     } 
     .OfferTable_headerlogo__176yn{
      width:70px;
      height:35px
     }
    
}
@media screen and (max-device-width: 1024px) {
  .OfferTable_offertableheight__16WXF{
    overflow:auto;
    height:83vh;
   }
  .OfferTable_offerTableNavbar__2HghH {
   height: 10vh;
  }
  .OfferTable_singleColOfferTable__1herz{
    width: 70%;
  margin:auto
    
  }
  .OfferTable_doubleColOfferTable__1E-uS{
    width: 100%;
    margin:auto
   }
   .OfferTable_threeColOfferTable__3pY9F{
    width: 100%;
    margin:auto
   }
  
}
@media screen and (max-device-width: 600px) {
  .OfferTable_singleColOfferTable__1herz{
    width: 90%;
  margin:auto
    
  }
}
  /* <-----------------mobile screen-view(portrait)---------------> */
  @media screen and (max-device-width: 480px) 

    and (orientation: portrait) {
      .OfferTable_omdname__3LnwW{
        font-weight: 500;
        visibility: hidden;
      }
      .OfferTable_headername__Ri8FR {
        color: white;
       font-size: 1rem;
        max-width: 300px;
        font-weight: 500;
        white-space: nowrap; /* Corrected */
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .OfferTable_offertable__3FOkZ {
        zoom: 0.65;
       
      } 
      .OfferTable_headerlogo__176yn{
        width:70px;
        height:35px
       }
      .OfferTable_tableWidth__1NIjs {

        padding:0px;
        z-index: 2;
      }
      .OfferTable_offerTableNavbar__2HghH {
        min-width: 100vw;
        height: 120px;
        box-shadow: 0px 1px 5px 0px #00000040;
       
        /* overflow: hidden; */
        display: inline-block;
        align-items: center;
        margin: 0;
        padding: 10px 0px 0px 0px;
        /* border: 2px solid green; */
        box-sizing: border-box;
        position: -webkit-sticky;
        position: sticky;
        top: 40px;
        left: 0;
        bottom:0;
        z-index: 9;
        background:#fff;
      }
     
      .OfferTable_prorertyAddress__JlFvD{
         font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.6px;
        text-align: left;
        max-width:230px;
        margin-left: -15px;
        font-weight: 400;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height:49px;
          /* line-height: 29.5px; */
         
         
        }
        .OfferTable_backButton__3VObK{
          display: flex;
        margin-top: -35px;
          margin-left:5px;
          cursor:pointer
        }
        .OfferTable_navbarSection1__BOYkR {
          flex: 1 1; 
          display: flex;
          align-items: center;
          grid-gap: 2rem;
          gap: 2rem;
          justify-content: flex-start;
     
          width:100%
         
        }
.OfferTable_offerNumber__1PQE_{
  display:flex;
  margin-top:5px;
  margin-left:-15px;
  background:#71BB66;
  text-align:center;
  justify-content:center;
  align-items:center;
  border-radius:5px;
  font-size:12px;
  color:white;
  max-width:100px;
  grid-gap:3px;
  gap:3px;
  box-shadow:0px 1px 5px 0px #00000040;
  height:25px;
  padding:"1px 3px 1px 3px";
  }
  .OfferTable_navbarSection2__237W2 {
   
    height:30px;
    flex: 1 1; 
    display: flex;
    align-items: center;
    justify-content:space-around;
    
 padding-left:10px;
 padding-right: 30px;
    padding-top: -10px;
    margin-left:0px;

  
     
  }   
   .OfferTable_legendtext__qBjHj{
    text-decoration: underline;
   cursor: pointer; 
    font-size: 16px; 
    font-weight: 600;
     margin-left: 10px
    
  }
  .OfferTable_helpicon__oHcKq{
    font-size: 1px;
    text-align:left;
    margin-right:-20px;
    margin-top: -5px;
    
    }
    .OfferTable_menuIcon__R8b0F{
      font-weight: bold;
      font-size: 25px;
      margin-right:-30px ;
      text-align: right;
      margin-top:-5px
    }
    .OfferTable_import__1IPEk{
      font-size: 20px;
      /* cursor: pointer; */
      font-family:bold;
      padding-top: 5px;
      /* color: black; */
    
    }
    .OfferTable_legend__16Tqu{
      font-size: 20px;
      cursor: pointer;
      font-family:bold;
      padding-top: 5px;
      /* color: black; */
    
  
    }
    
.OfferTable_singleColOfferTable__1herz{
  width: 100%;
margin:auto
  
}
.OfferTable_doubleColOfferTable__1E-uS{
  width: 100%;
  margin:auto
 }
 .OfferTable_threeColOfferTable__3pY9F{
  width: 100%;
  margin:auto
 }

  .OfferTable_offertableheight__16WXF{
  border-top-left-radius:15px;
    overflow:auto;
height:auto
  
} 
.OfferTable_criteria__2jhRj{

  text-transform:capitalize;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  display: flex;
  

}
.OfferTable_firstColAlignment__1oP1k {
  background-color: #F2F2F2 !important;
  /* box-shadow: 0px 0px 4px 0px #00000040; */

max-width: 200px;
min-width: 200px;
 text-transform: capitalize;
  text-align: center;
position: relative;
border:1px solid lightgray;
border-right: 5px solid #F2F2F2;
  top: 0;
  left: 0;
 
font-size: 15px;
font-weight: 600;
line-height: 24px;
margin-bottom: 16px;

letter-spacing: 0.12999999523162842px;
text-align: center;


}
.OfferTable_otherColAlignment__3ob46 {
  max-width: 200px;
  min-width: 200px;
  height:85px;
   border-left: 5px solid #F2F2F2; /* Add a left border */
   border-right: 5px solid #F2F2F2;
  
   font-weight: 400;
   font-size: 15px;
   
   
   
 }
 .OfferTable_otherColhiddenAlignment__2_Shf {
   max-width: 200px;
   min-width: 200px;
   height:85px;
    border-left: 5px solid #F2F2F2; /* Add a left border */
    border-right: 5px solid #F2F2F2;
    font-weight: 400;
    font-size: 15px;
    color:darkgray;
    background-color:rgb(251, 249, 249);
  }
  .OfferTable_offertablenumber__1DObJ{
    font-weight: 600;
     display:flex;
      align-items: center;
       font-size: 15px;
       color:#fff;
        margin-top: -5px
    }
    .OfferTable_header__3VCvI{
      width: 100vw; 
      height: 40px;
       background: rgb(8, 81, 145);
         padding: 0px 25px;
         position:-webkit-sticky ;
         position:sticky ;
         top: 0; left: 0;
         z-index:10
     }
     .OfferTable_omdheading__Wibf2{
      visibility: hidden;
      display: flex; align-items: center;
       justify-content: center;
       color:white;
          font-size: 15px
       }
  
}

/* For Mobile Landscape View */
@media screen and (max-device-width: 1080px) 
    and (orientation: landscape) {
       .OfferTable_offertable__3FOkZ {
        zoom: 0.75;
       
      } 
      .OfferTable_omdheading__Wibf2{
        display: flex;
        padding-top: 5px;
         align-items: center;
         justify-content: center;
         color:white;
            font-size: 15px
         }
      .OfferTable_tableWidth__1NIjs {

        padding:5px;
        z-index: 2;
      }
   
      .OfferTable_offerTableNavbar__2HghH {
        min-width: 100vw;
        height: 75px;
        box-shadow: 0px 1px 5px 0px #00000040;
        overflow: hidden;
        display: flex;
        align-items: center;
        margin: 0;
        width:100%;
        padding: 0px 40px  ;
        /* border: 2px solid green; */
        box-sizing: border-box;
        position: relative;
        top: 0;
        left: 0;
        z-index: 9;
        background: #fff;
      }
      /* .propertybox{
        display: flex;
        
      } */
      .OfferTable_prorertyAddress__JlFvD{
        font-size: 16px;
    
       font-weight: 400;
       line-height: 20px;
       letter-spacing: 0.6px;
       text-align: left;
       max-width:200px;
      margin-left: -15px;
       font-weight: 400;
       overflow: hidden;
       display: -webkit-box;
       -webkit-line-clamp: 2;
       -webkit-box-orient: vertical;
       max-height:49px;
       }
      
        .OfferTable_legendtext__qBjHj{
          text-decoration: underline;
          cursor: pointer; 
          font-size: 16px; 
          font-weight: 600;
           margin-left: 10px
          
        }
        
  .OfferTable_helpicon__oHcKq{
    font-size: 1px;
    text-align:left;
    margin-right:-20px;
    margin-top: -5px;
    
    }
    .OfferTable_menuIcon__R8b0F{
      font-weight: bold;
      font-size: 20px;
      margin-right:-30px ;
      text-align: right;
      margin-top:-5px
    }
    .OfferTable_import__1IPEk{
      font-size: 20px;
      /* cursor: pointer; */
      font-family:bold;
      padding-top: 5px;
      color: black;
    
    }
    .OfferTable_legend__16Tqu{
      font-size: 20px;
      cursor: pointer;
      font-family:bold;
      padding-top: 5px;
      /* color: black; */
    
  
    }
    .OfferTable_propertyimage__1zZm0{
      width: 100%; 
     
      height: 60px;
      border-radius:5px;
      color:rgb(251, 249, 249)
     }
         
.OfferTable_singleColOfferTable__1herz{
  width: 50%;
margin:auto
  
}
.OfferTable_doubleColOfferTable__1E-uS{
  width: 70%;
  margin:auto
 }
 .OfferTable_threeColOfferTable__3pY9F{
  width: 100%;
  margin:auto
 }
 .OfferTable_offertablenumber__1DObJ{
  font-weight: 600;
   display:flex;
    align-items: center;
     font-size: 15px;
     color:#fff;

  }
  .OfferTable_criteria__2jhRj{

    text-transform:capitalize;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    display: flex;
    /* justify-content: center;
    align-items: flex-start */
  
  }
  .OfferTable_firstColAlignment__1oP1k {
    background-color: #F2F2F2 !important;
    /* box-shadow: 0px 0px 4px 0px #00000040; */
  
  max-width: 200px;
  min-width: 200px;
   text-transform: capitalize;
    text-align: center;
  position: relative;
  border:1px solid lightgray;
  border-right: 5px solid #F2F2F2;
    top: 0;
    left: 0;
   
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 16px;
  
  letter-spacing: 0.12999999523162842px;
  text-align: center;
  
  
  }
  .OfferTable_otherColAlignment__3ob46 {
    max-width: 200px;
    min-width: 200px;
    height:85px;
     border-left: 5px solid #F2F2F2; /* Add a left border */
     border-right: 5px solid #F2F2F2;
    
     font-weight: 400;
     font-size: 15px;
     
     
     
   }
   .OfferTable_otherColhiddenAlignment__2_Shf {
     max-width: 200px;
     min-width: 200px;
     height:85px;
      border-left: 5px solid #F2F2F2; /* Add a left border */
      border-right: 5px solid #F2F2F2;
      font-weight: 400;
      font-size: 15px;
      color:darkgray;
      background-color:rgb(251, 249, 249);
    }
    .OfferTable_navbarSection1__BOYkR {
      flex: 1 1; 
      display: flex;
      align-items: center;
      grid-gap: 2rem;
      gap: 2rem;
      justify-content: flex-start;

      width:100%
     
    }
.OfferTable_offerNumber__1PQE_{
display:flex;
margin-top:5px;
margin-left:-15px;
background:#71BB66;
text-align:center;
justify-content:center;
align-items:center;
border-radius:5px;
font-size:12px;
color:white;
max-width:100px;
grid-gap:3px;
gap:3px;
box-shadow:0px 1px 5px 0px #00000040;
height:20px;
padding:"1px 3px 1px 3px";
}
    .OfferTable_navbarSection2__237W2 {
   max-width: 40%;
      flex: 1 1; 
      display: flex;
      align-items: center;
      /* gap: 2rem; */
      justify-content: flex-end;
      padding-top: 5px;
       grid-gap: "5px";
       gap: "5px";
    
    }
    .OfferTable_offertableheight__16WXF{
      border-top-left-radius:15px;
        overflow:auto;
      height:100%;
      
    }
    .OfferTable_header__3VCvI{
      width: 100vw; 
      height: 40px;
       background: rgb(8, 81, 145);
         padding: 0px 25px;
         position:-webkit-sticky ;
         position:sticky ;
         top: 0; left: 0;
         z-index:10
     }
}

/* ----- css for ios device ----- */
@media only screen and (-webkit-touch-callout: none) and (orientation: portrait) {

  .OfferTable_omdheading__Wibf2{
    display: flex; align-items: center;
     justify-content: center;
     color:white;
     visibility: hidden;
        font-size: 15px
     }
      .OfferTable_offertable__3FOkZ {
        zoom: 0.65;
       
      } 
      
      .OfferTable_tableWidth__1NIjs {

        padding:0px;
        z-index: 2;
      }
      .OfferTable_offerTableNavbar__2HghH {
        min-width: 100vw;
        height: 120px;
        box-shadow: 0px 1px 5px 0px #00000040;
       
        /* overflow: hidden; */
        display: inline-block;
        align-items: center;
        margin: 0;
        padding: 10px 0px 0px 0px;
        /* border: 2px solid green; */
        box-sizing: border-box;
        position: -webkit-sticky;
        position: sticky;
        top: 40px;
        left: 0;
        bottom:0;
        z-index: 9;
        background:#fff;
      }
     
      .OfferTable_prorertyAddress__JlFvD{
         font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.6px;
        text-align: left;
        max-width:230px;
        margin-left: -15px;
        font-weight: 400;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height:49px;
          /* line-height: 29.5px; */
         
         
        }
        .OfferTable_backButton__3VObK{
          display: flex;
        margin-top: -35px;
          margin-left:5px;
          cursor:pointer
        }
        .OfferTable_navbarSection1__BOYkR {
          flex: 1 1; 
          display: flex;
          align-items: center;
          grid-gap: 2rem;
          gap: 2rem;
          justify-content: flex-start;
     
          width:100%
         
        }
.OfferTable_offerNumber__1PQE_{
  display:flex;
  margin-top:5px;
  margin-left:-15px;
  background:#71BB66;
  text-align:center;
  justify-content:center;
  align-items:center;
  border-radius:5px;
  font-size:12px;
  color:white;
  max-width:100px;
  grid-gap:3px;
  gap:3px;
  box-shadow:0px 1px 5px 0px #00000040;
  height:25px;
  padding:"1px 3px 1px 3px";
  }
  .OfferTable_navbarSection2__237W2 {
   
    height:30px;
    flex: 1 1; 
    display: flex;
    align-items: center;
    justify-content:space-around;
    
 padding-left:10px;
 padding-right: 30px;
    padding-top: -10px;
    margin-left:0px;

  
     
  }   
   .OfferTable_legendtext__qBjHj{
    text-decoration: underline;
    
    cursor: pointer; 
    font-size: 16px; 
    font-weight: 600;
     margin-left: 10px
    
  }
  .OfferTable_helpicon__oHcKq{
    font-size: 1px;
    text-align:left;
    margin-right:-20px;
    margin-top: -5px;
    
    }
    .OfferTable_menuIcon__R8b0F{
      font-weight: bold;
      font-size: 25px;
      margin-right:-30px ;
      text-align: right;
      margin-top:-5px
    }
    .OfferTable_import__1IPEk{
      font-size: 20px;
      cursor: pointer;
      font-family:bold;
      padding-top: 5px;
      color: black;
    
    }
    .OfferTable_legend__16Tqu{
      font-size: 20px;
      cursor: pointer;
      font-family:bold;
      padding-top: 5px;
      /* color: black; */
    
  
    }
    
.OfferTable_singleColOfferTable__1herz{
  width: 100%;
margin:auto
  
}
.OfferTable_doubleColOfferTable__1E-uS{
  width: 100%;
  margin:auto
 }
 .OfferTable_threeColOfferTable__3pY9F{
  width: 100%;
  margin:auto
 }

  .OfferTable_offertableheight__16WXF{
  border-top-left-radius:15px;
    overflow:auto;
height:auto
  
} 
.OfferTable_criteria__2jhRj{

  text-transform:capitalize;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  display: flex;
  

}
.OfferTable_firstColAlignment__1oP1k {
  background-color: #F2F2F2 !important;
  /* box-shadow: 0px 0px 4px 0px #00000040; */

max-width: 200px;
min-width: 200px;
 text-transform: capitalize;
  text-align: center;
position: relative;
border:1px solid lightgray;
border-right: 5px solid #F2F2F2;
  top: 0;
  left: 0;
 
font-size: 15px;
font-weight: 600;
line-height: 24px;
margin-bottom: 16px;

letter-spacing: 0.12999999523162842px;
text-align: center;


}
.OfferTable_otherColAlignment__3ob46 {
  max-width: 200px;
  min-width: 200px;
 
   border-left: 5px solid #F2F2F2; /* Add a left border */
   border-right: 5px solid #F2F2F2;
  
   font-weight: 400;
   font-size: 15px;
   
   
   
 }
 .OfferTable_otherColhiddenAlignment__2_Shf {
   max-width: 200px;
   min-width: 200px;
 
    border-left: 5px solid #F2F2F2; /* Add a left border */
    border-right: 5px solid #F2F2F2;
    font-weight: 400;
    font-size: 15px;
    color:darkgray;
    background-color:rgb(251, 249, 249);
  }
  .OfferTable_offertablenumber__1DObJ{
    font-weight: 600;
     display:flex;
      align-items: center;
       font-size: 15px;
       color:#fff;
        margin-top: -5px
    }
    .OfferTable_header__3VCvI{
      width: 100vw; 
      height: 40px;
       background: rgb(8, 81, 145);
         padding: 0px 25px;
         position:-webkit-sticky ;
         position:sticky ;
         top: 0; left: 0;
         z-index:10
     }
  

}

@media only screen and (-webkit-touch-callout: none) and (orientation: landscape) 
   {
    .OfferTable_omdheading__Wibf2{
      display: flex; align-items: center;
       justify-content: center;
       color:white;
       padding-top: 5px;
          font-size: 15px
       }
       .OfferTable_offertable__3FOkZ {
        zoom: 0.75;
       
      } 
       
      .OfferTable_tableWidth__1NIjs {

        padding:5px;
        z-index: 2;
      }
   
      .OfferTable_offerTableNavbar__2HghH {
        min-width: 100vw;
        height: 75px;
        box-shadow: 0px 1px 5px 0px #00000040;
        overflow: hidden;
        display: flex;
        align-items: center;
        margin: 0;
        width:100%;
        padding: 0px 40px  ;
        /* border: 2px solid green; */
        box-sizing: border-box;
        position: relative;
        top: 0;
        left: 0;
        z-index: 9;
        background: #fff;
      }
      /* .propertybox{
        display: flex;
        
      } */
      .OfferTable_prorertyAddress__JlFvD{
        font-size: 16px;
    
       font-weight: 400;
       line-height: 20px;
       letter-spacing: 0.6px;
       text-align: left;
       max-width:150px;
       margin-left: -15px;
       font-weight: 400;
       overflow: hidden;
       display: -webkit-box;
       -webkit-line-clamp: 2;
       -webkit-box-orient: vertical;
       max-height:49px;
       }
      
        .OfferTable_legendtext__qBjHj{
          text-decoration: underline;
          cursor: pointer; 
          font-size: 16px; 
          font-weight: 600;
           margin-left: 10px
          
        }
        
  .OfferTable_helpicon__oHcKq{
    font-size: 1px;
    text-align:left;
    margin-right:-20px;
    margin-top: -5px;
    
    }
    .OfferTable_menuIcon__R8b0F{
      font-weight: bold;
      font-size: 20px;
      margin-right:-30px ;
      text-align: right;
      margin-top:-5px
    }
    .OfferTable_import__1IPEk{
      font-size: 20px;
      cursor: pointer;
      font-family:bold;
      padding-top: 5px;
      color: black;
    
    }
    .OfferTable_legend__16Tqu{
      font-size: 20px;
      cursor: pointer;
      font-family:bold;
      padding-top: 5px;
      /* color: black; */
    
  
    }
    .OfferTable_propertyimage__1zZm0{
      width: 100%; 
     
      height: 60px;
      border-radius:5px;
      color:rgb(251, 249, 249)
     }
         
.OfferTable_singleColOfferTable__1herz{
  width: 50%;
margin:auto
  
}
.OfferTable_doubleColOfferTable__1E-uS{
  width: 70%;
  margin:auto
 }
 .OfferTable_threeColOfferTable__3pY9F{
  width: 100%;
  margin:auto
 }
 .OfferTable_offertablenumber__1DObJ{
  font-weight: 600;
   display:flex;
    align-items: center;
     font-size: 15px;
     color:#fff;

  }
  .OfferTable_criteria__2jhRj{

    text-transform:capitalize;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    display: flex;
    /* justify-content: center;
    align-items: flex-start */
  
  }
  .OfferTable_firstColAlignment__1oP1k {
    background-color: #F2F2F2 !important;
    /* box-shadow: 0px 0px 4px 0px #00000040; */
  
  max-width: 220px;
  min-width: 220px;
   text-transform: capitalize;
    text-align: center;
  position: relative;
  border:1px solid lightgray;
  border-right: 5px solid #F2F2F2;
    top: 0;
    left: 0;
   
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 16px;
  
  letter-spacing: 0.12999999523162842px;
  text-align: center;
  
  
  }
  .OfferTable_otherColAlignment__3ob46 {
    max-width: 220px;
    min-width: 220px;
  
     border-left: 5px solid #F2F2F2; /* Add a left border */
     border-right: 5px solid #F2F2F2;
    
     font-weight: 400;
     font-size: 15px;
     
     
     
   }

   .OfferTable_otherColhiddenAlignment__2_Shf {
     max-width: 220px;
     min-width: 220px;
     
      border-left: 5px solid #F2F2F2; /* Add a left border */
      border-right: 5px solid #F2F2F2;
      font-weight: 400;
      font-size: 15px;
      color:darkgray;
      background-color:rgb(251, 249, 249);
    }
    .OfferTable_navbarSection1__BOYkR {
      flex: 1 1; 
      display: flex;
      align-items: center;
      grid-gap: 2rem;
      gap: 2rem;
      justify-content: flex-start;

      width:100%
     
    }
.OfferTable_offerNumber__1PQE_{
display:flex;
margin-top:5px;
margin-left:-15px;
background:#71BB66;
text-align:center;
justify-content:center;
align-items:center;
border-radius:5px;
font-size:12px;
color:white;
max-width:100px;
grid-gap:3px;
gap:3px;
box-shadow:0px 1px 5px 0px #00000040;
height:20px;
padding:"1px 3px 1px 3px";
}
    .OfferTable_navbarSection2__237W2 {
   max-width: 40%;
      flex: 1 1; 
      display: flex;
      align-items: center;
      /* gap: 2rem; */
      justify-content: flex-end;
      padding-top: 5px;
       grid-gap: "5px";
       gap: "5px";
    
    }
    .OfferTable_offertableheight__16WXF{
      border-top-left-radius:15px;
        overflow:auto;
      height:100%;
      
    }
    .OfferTable_header__3VCvI{
      width: 100vw; 
      height: 40px;
       background: rgb(8, 81, 145);
         padding: 0px 25px;
         position:-webkit-sticky ;
         position:sticky ;
         top: 0; left: 0;
         z-index:10
     }
}

.SendEmailModal_my-checkbox__2zeJ5 {
    border: 2px solid #1677FF !important;
    border-radius: 4px !important;
}

.SendEmailModal_customInput__16lD0 .SendEmailModal_ant-input__3cYMg::placeholder {
    color: #1677FF !important;
}

.SendEmailModal_addEmailForm__3H0IT {
    justify-content: left;
    margin: 10px 0 -10px;
}

.SendEmailModal_flexContatiner__1K4wZ {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.SendEmailModal_groupLabel__2pgkH {
    font-size: 18px;
    color: grey;
    font-weight: bold;
}

.SendEmailModal_bulletPoint__zyiF5,
.SendEmailModal_dash__27Yy7,
.SendEmailModal_fullName__-oMLG,
.SendEmailModal_email__1KKD3 {
    font-size: 16px;
}

.SendEmailModal_signlePersonRow__2s-d1 {
    display: flex;
    align-items: center;
    white-space: nowrap;
    grid-gap: 10px;
    gap: 10px;
}

.SendEmailModal_fullName__-oMLG {
    cursor: context-menu;
    text-transform: capitalize;
}

.SendEmailModal_email__1KKD3 {
    text-decoration: underline;
    color: blue !important;
    cursor: context-menu;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}



.SendEmailModal_bulletPoint__zyiF5 {
    margin: 0 8px 0 28px;
    color: grey;
}

.SendEmailModal_dash__27Yy7 {
    font-weight: bold;
    cursor: context-menu;
    margin: 0 5px;
}

.SendEmailModal_spin__1QNwT {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.SendEmailModal_readOnly__2C5FT {
    font-size: 14px;
    color: grey;
}

.SendEmailModal_emailSignature__1NNiY {
    font-size: 16px;
    margin-left: 5px;
}

.SendEmailModal_documentHeader__1ldJS {
    font-size: 14px;
    color: grey;
}

.SendEmailModal_subHeader__1KWBQ {
    font-size: 14px;
    color: grey;
}

.SendEmailModal_confirmTitle__Jyx3M {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SendEmailModal_confirmModalContent__3yDYb {
    margin-top: 5px;
    width: 100%;
    margin-left: -15px;
    overflow-x: scroll;
    max-height: 450px;
    overflow-y: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.SendEmailModal_confirmModalContent__3yDYb::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, and Opera */
}

@media (min-width: 601px) and (max-width: 1024px) {

    .SendEmailModal_fullName__-oMLG,
    .SendEmailModal_documentHeader__1ldJS,
    .SendEmailModal_docName__A7O6F {
        font-size: 14px;
    }

    .SendEmailModal_email__1KKD3 {
        font-size: 14px;
    }

    .SendEmailModal_recipient__1ersX,
    .SendEmailModal_confirmTitle__Jyx3M {
        font-size: 14px;
    }

    .SendEmailModal_groupLabel__2pgkH,
    .SendEmailModal_dash__27Yy7 {
        font-size: 14px;
    }

    .SendEmailModal_emailSignature__1NNiY {
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    .SendEmailModal_addEmailForm__3H0IT {
        margin-top: 15px;
    }

    .SendEmailModal_fullName__-oMLG,
    .SendEmailModal_documentHeader__1ldJS,
    .SendEmailModal_docName__A7O6F {
        font-size: 12px;
    }

    .SendEmailModal_email__1KKD3 {
        font-size: 12px;
    }

    .SendEmailModal_confirmTitle__Jyx3M {
        font-size: 12px;
    }

    .SendEmailModal_groupLabel__2pgkH {
        font-size: small;
        font-weight: bold;
    }

    .SendEmailModal_dash__27Yy7 {
        font-size: 12px;
    }

    .SendEmailModal_readOnly__2C5FT {
        font-size: 10px;
    }

    .SendEmailModal_bulletPoint__zyiF5 {
        margin: 0 8px 0 25px;
        color: grey;
    }

    .SendEmailModal_emailSignature__1NNiY {
        font-size: 12px;
    }

    .SendEmailModal_subHeader__1KWBQ {
        font-size: 12px;
        margin-top: 2px;
    }
}

.ClientList_demoloadlisting__iUo-0 {
  color: #143b7c;
}

.ClientList_clientListItem__2GzmQ {
  cursor: pointer;
}

.ClientList_useravtar__QcGWJ {
  background-color: #000;
  width: 40px;
  height: 40px;
}

.ClientList_clientListItem__2GzmQ:hover {
  background-color: rgba(42, 82, 196, 0.1);
  /* color: #fff !important; */
}

.ClientList_formcreate__F7bH9 label {
  font-size: 16px !important;
  font-weight: 600;
}

.ClientList_formcreate__F7bH9 label:before {
  position: absolute;
  right: -14px;
}

.ClientList_formcreate__F7bH9 label.ClientList_item-required__1VTdT:not(.ClientList_item-required-mark-optional__-CLN4)::before {
  margin-right: 0;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
  right: -10px;
  position: absolute;
}

.ClientList_createtitle__2sjuT {
  font-size: 26px;
  font-weight: 600;
  padding: 0px 0 8px 0;
  /* border-bottom: 2px solid #f0f0f0; */
  margin-bottom: 11px;
}

.FilterContacts_filterModal__27Swb{
    display: grid;
    grid-template-columns: repeat(2,2fr);
    grid-gap:10px;
    gap:10px
}

@media only screen and (max-width:560px) {
    .FilterContacts_filterModal__27Swb{
        display: grid;
        grid-template-columns: repeat(1,4fr);
    }
}

.container {
  width: 100%;
  height: 100vh;
  top: 10%;
  left: 10%;
}

.sigContainer {
  width: 80%;
  height: 80%;
  margin: 0 auto;
  background-color: #fff;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.buttons {
  width: 100%;
  height: 30px;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}

.HandleSignatureBox_signatureConfirmModal__nlQKQ>div {
    border-radius: 7px;
    background-color: #ffffff;
    padding: 0;
    max-width: 70vh;
}

.HandleSignatureBox_confirmIPDivContents__3dKEg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.HandleSignatureBox_contentItems__1ekp3 {
    display: flex;
    align-items: flex-start;
    grid-gap: 20px;
    gap: 20px;
}
.HandleSignatureBox_confirmButtons__2hUIg {
    display: flex;
    grid-gap: 30px;
    gap: 30px;
    justify-content: center;
    margin-top: 30px;
}

@media screen  and (max-width: 500px) {
    .HandleSignatureBox_confirmIPDivContents__3dKEg {
        font-size: small;
    }
    .HandleSignatureBox_confirmButtons__2hUIg {
        margin-top: 10px;
        grid-gap: 20px;
        gap: 20px;
    }

}
.CobuyerCosellerList_contentDiv__2Yrki {
    margin-top: -20px;
    padding: 10px;
    min-height: 60vh;
    max-height: 60vh;
    overflow-y: auto;
    /* max-height: 60vh;
    /* overflow: auto; */ 
}
.CobuyerCosellerList_personheading__3-guH{
    font-weight: bold;
    justify-content: space-between;
    display: flex;
    font-size: 20px;
    width:100%;
    color: #1890ff;
    cursor:"pointer"

}
.CobuyerCosellerList_corpheading__1KQyC{
    font-weight: bold;
    justify-content: space-between;
    display: flex;
    font-size: 20px;
    margin-top:-5px;
    width:100%;
    color: #1890ff;


}
.CobuyerCosellerList_formItem__n37sy {
    margin: 0;
    width: 100%;
}
.CobuyerCosellerList_inputBoxDiv__2jL26 {
    display: flex;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
}

.CobuyerCosellerList_selectedRowsOuterDivParent__1f3qB {
    width: 100%;
}
.CobuyerCosellerList_selectedRowsOuterDiv__4476h {
 overflow: hidden;
 margin-left: -10px;
 margin-bottom: -20px;/* Set maximum height */
}

.CobuyerCosellerList_infoText__3uoRU{
    text-align: center;
    color: #0707ff;
    margin: -4px 0 20px 0;
    font-size: 15px;
}

@media screen and (max-width: 500px) {
    .CobuyerCosellerList_contentDiv__2Yrki {
        padding: 5px;
    }
    .CobuyerCosellerList_formItem__n37sy {
        margin: 0%;
    }
    .CobuyerCosellerList_inputBoxDiv__2jL26 input{
        margin-left: 5px;
    }
    .CobuyerCosellerList_selectedRowsOuterDiv__4476h {
        max-height: 500px;
        font-size: small;
    }
    .CobuyerCosellerList_infoText__3uoRU{
        text-align: left;
    }
}

.offerImport_Customlbl__1AuXn {
  font-size: 16px;
  color: gray;
  font-weight: 500;
  margin-bottom: 5px;
}
.offerImport_formRow__afTAY {
  margin-top: 15px;
}
.offerImport_OIDraggerWrapper__3J0UB {
  width: 90%;
  height: 250px;
  margin: auto;
  text-align: center;
  margin-bottom: 80px;
}
.offerImport_cheboxWrapperDiv__2VCF1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.offerImport_otherInputWrapper__2Pmi1 {
  margin-left: 10px;
}

.offerImport_inputWrapper__HkD5_ {
  position: relative;
  width: 100%;
}

.offerImport_inputWrapper__HkD5_ input {
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
}

.offerImport_inputWrapper__HkD5_ .offerImport_currencyIcon__14Unn ~ input {
  padding-left: 30px;
}

.offerImport_currencyIcon__14Unn {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.offerImport_validationMessage__1qOuE {
  display: none;
  color: red;
  font-size: 12px;
  position: absolute;
  left: 10px;
}

.offerImport_inputWrapper__HkD5_ input:invalid ~ .offerImport_validationMessage__1qOuE {
  display: block;
}

.offerImport_inputWrapper__HkD5_ input:valid ~ .offerImport_validationMessage__1qOuE {
  display: none;
}

@media screen and (max-width: 992px) {
  /* Styles for medium screens */
  .offerImport_OIDraggerWrapper__3J0UB {
    width: 95%;
    height: 230px;
    margin-bottom: 100px;
  }
  .offerImport_cheboxWrapperDiv__2VCF1 {
    flex-direction: column;
    align-items: flex-start;
  }
  .offerImport_otherInputWrapper__2Pmi1 {
    margin-left: 0px;
    margin-top: 5px;
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  /* Styles for small screens */
  .offerImport_OIDraggerWrapper__3J0UB {
    width: 100%;
    height: 200px;
    margin-bottom: 100px;
  }
}

.brokerDetailEditConfirmModal .ant-modal-close-x {
    width: 25px;
    height: 25px;
    line-height: normal;
}
.NetSheetAddItemModal_feesLabel__3vguM:hover {
    background-color: rgb(204, 226, 246);

}
.PdfUpload_layoutAlignment__3cfzS {
  padding: 0px;
  position: absolute;
  left: 54%;
  top: 12%;
  transform: translate(-50%);
}

.PdfUpload_contentAlignment__32WhK {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.PdfUpload_cardAlignment__3YvlF {
  width: 400px;
}

.PdfUpload_buttonAlignment__2kd0H {
  text-align: center;
}

@media (min-width: 280px) {
  .PdfUpload_layoutAlignment__3cfzS {
    padding: 0px;
    position: absolute;
    left: 54%;
    top: 13%;
    transform: translate(-50%);
  }

  .PdfUpload_contentAlignment__32WhK {
    position: absolute;
    left: 50%;
    transform: translate(-54%);
  }

  .PdfUpload_cardAlignment__3YvlF {
    width: 250px;
    height: 75vh;
  }
}

@media (min-width: 320px) {
  .PdfUpload_layoutAlignment__3cfzS {
    padding: 0px;
    position: absolute;
    left: 54%;
    top: 17%;
    transform: translate(-50%);
  }

  .PdfUpload_contentAlignment__32WhK {
    position: absolute;
    left: 50%;
    transform: translate(-54%);
  }

  .PdfUpload_cardAlignment__3YvlF {
    width: 280px;
    height: 70vh;
  }
}
@media (min-width: 480px) {
  .PdfUpload_layoutAlignment__3cfzS {
    padding: 0px;
    position: absolute;
    left: 54%;
    top: 17%;
    transform: translate(-50%);
  }

  .PdfUpload_contentAlignment__32WhK {
    position: absolute;
    left: 50%;
    transform: translate(-54%);
  }

  .PdfUpload_cardAlignment__3YvlF {
    height: 70vh;
    width: 340px;
  }
}
@media (min-width: 600px) {
  .PdfUpload_layoutAlignment__3cfzS {
    padding: 0px;
    position: absolute;
    left: 54%;
    top: 12%;
    transform: translate(-50%);
  }

  .PdfUpload_contentAlignment__32WhK {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }

  .PdfUpload_cardAlignment__3YvlF {
    /* width: 400px; */
    /* height: 70vh; */
    /* height: 50vh; */
    width: 250px;
    height: 75vh;
  }
  .PdfUpload_buttonAlignment__2kd0H {
    text-align: end;
  }
}
@media (min-width: 801px) {
  .PdfUpload_layoutAlignment__3cfzS {
    padding: 0px;
    position: absolute;
    left: 54%;
    top: 12%;
    transform: translate(-50%);
  }

  .PdfUpload_contentAlignment__32WhK {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }

  .PdfUpload_cardAlignment__3YvlF {
    /* width: 400px;
    height: 50vh; */
    width: 250px;
    height: 75vh;
  }
  .PdfUpload_buttonAlignment__2kd0H {
    text-align: end;
  }
}
@media (min-width: 1025px) {
  .PdfUpload_layoutAlignment__3cfzS {
    padding: 0px;
    position: absolute;
    left: 54%;
    top: 12%;
    transform: translate(-50%);
  }

  .PdfUpload_contentAlignment__32WhK {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }

  .PdfUpload_cardAlignment__3YvlF {
    width: 400px;
    height: 56vh;
  }
  .PdfUpload_buttonAlignment__2kd0H {
    text-align: end;
  }
}
@media (min-width: 1281px) {
  .PdfUpload_layoutAlignment__3cfzS {
    padding: 0px;
    position: absolute;
    left: 54%;
    top: 12%;
    transform: translate(-50%);
  }

  .PdfUpload_contentAlignment__32WhK {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }

  .PdfUpload_cardAlignment__3YvlF {
    width: 400px;
    height: 56vh;
  }

  .PdfUpload_buttonAlignment__2kd0H {
    text-align: end;
  }
}

/* #components-layout-demo-responsive  */
.PrivateRoute_logo__1PhiX {
  /* height: 32px; */
  margin: 16px;
  width: 60px;
  height: auto;
  /* background: rgba(255, 255, 255, 0.2); */
}

.PrivateRoute_site_layout_sub_header_background__3lUTv {
  background: #fff;
}

.PrivateRoute_site_layout_background__3s7RM {
  background: #fff;
}

.PrivateRoute_menuScrollBox__2CJGq::-webkit-scrollbar {
    display: none;
  }
  
.PrivateRoute_menuScrollBox__2CJGq .PrivateRoute_ant-menu-item__qZLxG{
   white-space: normal !important;
  }

.PrivateRoute_trigger__1mBq7 {
  padding: 0 24px;
  font-size: 25px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  color: rgb(78, 115, 223);
}

.PrivateRoute_trigger__1mBq7:hover {
  color: rgb(185, 138, 138);
}

@media only screen and (max-width: 375px) {
    .PrivateRoute_help__o3geN{
         position: fixed;
         bottom: 20;
         /* right: 20; */
         left:30px;
         z-index: 999;
         width: 350px;
        }
}

.Sidebar_sectionName__1dfal {
    display: flex;
    max-height: 40px;
    width: 130px;
  }
  .Sidebar_sectionNameSelected__1tuV8{
    display: flex;
    max-height: 50px;
    width: 130px;
    background-color: #97C8F6;
  }
  .Sidebar_ant-menu-item__28IyW:focus {
    background-color: transparent;
  }
  .Sidebar_tabNameSelected__bUimC {
    color: #123A79;
    font-size: 24px;
  }
  .Sidebar_tabNameNotSelected__38_in {
    color: #8C8C8C;
    font-size: 20px;
  }
  .Sidebar_video__2GhZS{
    width: 100%;
    height: 600px;
    margin-top: 12px;
  }
  /* .helpDrwaer .ant-drawer-content-wrapper, .ant-drawer-wrapper-body, .ant-drawer-content{
    width: 500px !important;
  } */


  @media only screen and (max-device-width: 380px) {
    .Sidebar_sectionName__1dfal {
      width: 109px;
    }
    .Sidebar_tabNameSelected__bUimC {
      font-size: 20px;
    }
    .Sidebar_tabNameNotSelected__38_in {
      font-size: 16px;
    }
  }
  @media all and (min-width:480px) and (max-width:790px){
    .Sidebar_video__2GhZS {
      height: 300px;
    }
  }
  @media all and (max-width: 480px) {
    .Sidebar_video__2GhZS {
      height: 200px;
    }
  }

.ant-drawer-content-wrapper{
    width: 500px !important;
}

/* Make the arrows darker */
.ant-picker-header .ant-picker-header-super-prev-btn,
.ant-picker-header .ant-picker-header-prev-btn,
.ant-picker-header .ant-picker-header-next-btn,
.ant-picker-header .ant-picker-header-super-next-btn {
  color: #4f4f4f !important; /* Darker color for the arrows */
}

@media (min-width: 320px) {
    .ant-drawer-content-wrapper{
        width: 310px !important;
    }
  }

  @media (min-width: 360px) {
    .ant-drawer-content-wrapper{
        width: 350px !important;
    }
  }

  @media (min-width: 375px) {
    .ant-drawer-content-wrapper{
        width: 370px !important;
    }
  }

  @media (min-width: 411px) {
    .ant-drawer-content-wrapper{
        width: 400px !important;
    }
  }

  @media (min-width: 480px) {
    .ant-drawer-content-wrapper{
        width: 470px !important;
    }
  }
  @media (min-width: 600px) {
    .ant-drawer-content-wrapper{
        width: 400px !important;
    }
  }

.Logout_site_page_header__FIjjI {
  border: 1px solid rgb(235, 237, 240);
}

#Admin_components-form-demo-normal-login__2Gp_D .Admin_loginForm__29dT5 {
  max-width: 300px;
}
#Admin_components-form-demo-normal-login__2Gp_D .Admin_login-form-forgot__20-mH {
  float: right;
}
#Admin_components-form-demo-normal-login__2Gp_D .Admin_ant-col-rtl__3A-3N .Admin_loginFormForgot__kdgXS {
  float: left;
}
#Admin_components-form-demo-normal-login__2Gp_D .Admin_loginFormButton__3YRhf {
  width: 100%;
}

.Admin_alignAdmin__20mG6 {
  background-image: url(https://i.pinimg.com/originals/bb/98/55/bb9855e4ce31410e9289453ddb447ceb.jpg);
  background-position: top center;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  max-height: 100%;
  background-repeat: no-repeat;
  background-color: #444;
  position: relative;
  overflow-x: hidden;
}

.Admin_alignAdmin__20mG6:after {
  content: "";
  background-color: #032345;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
}

.Admin_loginAlignment__1IdTT {
  position: absolute !important;
  opacity: 1.5;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Admin_Loginlogo__2cvmY {
  width: 100%;
  margin: 10px auto;
  display: block;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.Admin_custFormLogo__1tgcK {
  margin-top: -25px;
}

.Admin_custFormH1__3-TBl {
  font-size: 42px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #404b51;
  text-align: center;
  line-height: 55px;
  margin-bottom: 10px;
}
.Admin_custFormSignupH1__2lKEB {
  font-size: 21px;
  font-family: "Roboto", sans-serif;
  font-weight: 550;
  color: #8e959a;
  text-align: center;
  line-height: 0px;
  /* margin-bottom: -15px; */
  /* margin-top: -10px; */
}

.Admin_btnPrimary__3VFmZ {
  display: block !important;
  width: 100%;
  /* margin-bottom: 15px; */
  text-transform: uppercase;
  /* padding: 10px 20px; */
  border-radius: 30px !important;
  /* margin-top: -50px; */
}

.Admin_custFormH2__24yVl {
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #404b51;
  text-align: center;
  line-height: 37px;
  /* margin-top: 10px; */
}

.Admin_custForm__fJi2J {
  background-color: #fff;
  width: 550px;
  max-width: 90%;
  /* margin: 70px auto; */
  display: block;
  min-height: 300px;
  box-shadow: 0 0 8px #6c6969;
  /* padding: 40px 50px; */
  border-top: 8px solid #dbdfe3;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  position: absolute !important;
  opacity: 1.5;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.Admin_custFormSignup__1TC6W {
  /* background-color: red; */
  width: 550px;
  overflow-y: auto;
  /* max-width: 90%; */
  /* margin: 70px auto; */
  /* display: block; */
  min-height: 300px;
  box-shadow: 0 0 8px #6c6969;
  /* padding: 40px 50px; */
  /* border-top: 8px solid #dbdfe3; */
  box-sizing: border-box;
  /* position: relative; */
  z-index: 1;
  margin: auto;
  overflow-y: auto;
  height: 84vh;
  position: absolute !important;
  opacity: 1.5;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: -10 !important;
}

.Admin_phone-input__J-Fmb .Admin_react-phone-input__3R2BP {
  width: 286px !important;
}
.Admin_form-control__2o1On {
  width: 286px !important;
  height: 34px;
}

/* .adminCreateAccount label {
  padding: 5px 10px;
} */

.Dashboard_site_layout_background__1bwJW {
  background: #fff;
  /* padding: "24px"; */
  /* min-height: "100vh"; */
}

.Dashboard_cardSettings__3pFtJ {
  margin: 0 5px 5px 5px !important;
  max-width: 100% !important;
  position: relative !important;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff !important;
  background-clip: border-box !important;
  border: 1px solid #e3e6f0 !important;
  border-radius: 0.35rem !important;
  flex: 1 1 auto;
  min-height: 1px;
  /* padding: 1.25rem !important; */
  border-left: 0.25rem solid #4e73df !important;
}

.Dashboard_cardSettingsSecond__2y5Yo {
  border-left: 0.25rem solid #815ba5 !important;
}

.Dashboard_cardSettingsThird__3PY2d {
  border-left: 0.25rem solid #5ba575 !important;
}

.Dashboard_cardSettingsFourth__3SUst {
  border-left: 0.25rem solid red !important;
}

.Dashboard_genrateButton__3i8SX {
  display: flex !important;
  justify-content: flex-end;
  padding-right: 8px;
}

.Dashboard_genrateButtonTextChange__2AiEZ {
  content: "Genrate Reports" !important;
}

@media (min-width: 320px) {
  .Dashboard_genrateButtonTextChange__2AiEZ {
    content: "" !important;
    border-radius: 0.25rem !important;
    background-color: rgb(78, 115, 223) !important;
  }
}
@media (min-width: 480px) {
  .Dashboard_genrateButtonTextChange__2AiEZ {
    content: "" !important;
    border-radius: 0.25rem !important;
    background-color: rgb(78, 115, 223) !important;
  }
}
@media (min-width: 600px) {
  .Dashboard_genrateButtonTextChange__2AiEZ {
    content: "" !important;
    border-radius: 0.25rem !important;
    background-color: rgb(78, 115, 223) !important;
  }
}
@media (min-width: 801px) {
  .Dashboard_genrateButtonTextChange__2AiEZ {
    content: "Genrate Reports" !important;
    border-radius: 0.25rem !important;
    background-color: rgb(78, 115, 223) !important;
  }
}
@media (min-width: 1025px) {
  .Dashboard_genrateButtonTextChange__2AiEZ {
    content: "Genrate Reports" !important;
    border-radius: 0.25rem !important;
    background-color: rgb(78, 115, 223) !important;
  }
}
@media (min-width: 1281px) {
  .Dashboard_genrateButtonTextChange__2AiEZ {
    content: "Genrate Reports" !important;
    border-radius: 0.25rem !important;
    background-color: rgb(78, 115, 223) !important;
  }
}


.FranchiseList_clientLoader__1yK5i {
    background-color: #0e0e0eb3;
    width: 100%;
    height: calc(100vh - 6rem);
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    z-index: 1000;
    justify-content: center;
    align-items: center;
  }
  .FranchiseList_documentlistname__1Qkpp{
    font-size: 20px;
  }
  .FranchiseList_hoverable__1cbzR {
    font-weight: bold;
    font-family: sans-serif;
    cursor: pointer;
    color: grey;
  }
  .FranchiseList_hoverable__1cbzR :hover {
    color: #73b5f3;
  }
  .FranchiseList_plusicon__2F2fo{
    position: absolute;
       right: 60px;
  
   }
   .FranchiseList_notesmodal___NQm_{
    width:630px
   }
  .FranchiseList_brokerageLoading__2wwQU{
    margin-top: -60%;
  }
  @media screen and (max-width: 1200px) {
    .FranchiseList_clientLoader__1yK5i {
      height: 70vh;
    }
  }
  
  @media screen and (max-width: 768px) {
    .FranchiseList_clientLoader__1yK5i {
      height: 90vh;
    }
    .FranchiseList_documentlistname__1Qkpp{
      font-size: 16px;
    }
  }
  @media screen and (max-width: 568px) {
    .FranchiseList_clientLoader__1yK5i {
      height: 100vh;
    }
  }
  
  .FranchiseList_textAlignment__3-iY1 {
    margin-right: 2rem;
    fill: #000000;
    font-size: 1.3rem;
  }
  
  .FranchiseList_clientListItem__3KVKq {
    cursor: pointer;
  }
  .FranchiseList_subMenuList__3SDSG .FranchiseList_ant-list-bordered__1u3Qd {
    border: none !important;
    border-radius: 0px !important;
  }
  
  .FranchiseList_clientListItem__3KVKq:hover {
    background-color: rgba(42, 82, 196, 0.1);
    /* color: #fff !important; */
  }
  
  .FranchiseList_clientListItem__3KVKq:active {
    background-color: rgba(5, 59, 209, 0.1);
  }
  
  .FranchiseList_caret__1jWAk {
    cursor: pointer;
    -webkit-user-select: none; /* Safari 3.1+ */ /* Firefox 2+ */ /* IE 10+ */
    user-select: none;
  }
  
  .FranchiseList_caret__1jWAk::before {
    content: "\25B6";
    color: black;
    display: inline-block;
    margin-right: 6px;
  }
  
  .FranchiseList_caretDown__27Wzk::before { /* IE 9 */
    transform: rotate(90deg);
  }
  .FranchiseList_nested__UpLiY {
    display: none;
  }
  
  .FranchiseList_active__2Xn5C {
    display: block;
  }
  
  .FranchiseList_userlisting__3pSyr {
    border: 0;
    border-radius: 0;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 100%;
  }
  .FranchiseList_userlisting__3pSyr .FranchiseList_clientListItem__3KVKq {
    border: 1px solid #e9e2e2;
    margin-bottom: 2px;
  }
  
  .FranchiseList_userlisting__3pSyr .FranchiseList_clientListItem__3KVKq.FranchiseList_active__2Xn5C {
    background-color: rgba(42, 82, 196, 0.1);
  }
  
  .FranchiseList_userlisting__3pSyr .FranchiseList_clientListItem__3KVKq h4 {
    text-transform: capitalize;
    margin-bottom: 0;
    margin-top: 11px;
    font-size: 18px;
    color: #444444;
  }
  
  span.FranchiseList_useravtar__1ppvE>img {
    object-fit: contain;
  }
  
  .FranchiseList_clientdetails__1x3fy {
    width: 100%;
    float: left;
    margin-bottom: 15px;
  }
  
  .FranchiseList_clientdetails__1x3fy .FranchiseList_clientheading__19VQn {
    font-size: 1.613rem;
    color: #444444;
  }
  .FranchiseList_btn-primary__2TGC0 {
    height: auto !important;
    padding: 4px 10px !important;
    font-size: 14px !important;
    background: #0077d8 !important;
    border-color: #0077d8 !important;
  }
  
  .FranchiseList_iconsbox__3IM-4 {
    width: 100%;
    /* float: left; */
    display: flex;
    /* margin-bottom: 5px; */
  }
  
  .FranchiseList_iconsclient__3GSBq {
    width: 35px;
    float: left;
  }
  
  .FranchiseList_userfeild__2dqkA {
    width: 100%;
  
    /* float: left; */
    font-size: 18px;
    /* text-transform: capitalize; */
  }
  .FranchiseList_useremailfeild__3Al7z {
    width: 87%;
    /* float: left; */
    font-size: 18px;
    margin-left: 20px;
    text-align: left;
    /* text-transform: capitalize; */
  }
  .FranchiseList_usernoemailfeild__2P-Lu {
    width: 87%;
    font-size: 18px;
    margin-left: 17px;
    text-align: left;
    color: red;
  }
  .FranchiseList_useremailfeild__3Al7z:hover {
    color: #1890ff !important;
    cursor: pointer;
  }
  .FranchiseList_userfeild__2dqkA:hover {
    color: #1890ff !important;
    cursor: pointer;
  }
  
  .FranchiseList_profilephoto__crSs_ {
    width: 100%;
    text-align: center;
    padding-top: 25px;
    /* border: 2px solid red ; */
  }
  
  .FranchiseList_profilephoto__crSs_ .FranchiseList_profileimg__2hjqU {
    border-radius: 50%;
    margin: 0;
    margin-bottom: 10px;
    /* margin-left: 25; */
    width: 67px;
    height: 67px;
    object-fit: cover;
    /* margin-right: 57px; */
  }
  
  .FranchiseList_userevents__1-qp4 {
    color: #1968ff;
    text-decoration: underline;
  }
  
  .FranchiseList_tab-title__JaWQT img {
    width: 31px;
  }
  
  .FranchiseList_demo-loadmore-list__2FiaY {
    color: #143b7c;
  }
  
  .FranchiseList_demoloadlisting__VzFpP {
    color: #143b7c;
  }
  
  .FranchiseList_dotAlignment__h94VX {
    border: 1px solid black;
    width: 15px;
    height: 15px;
    background: white;
    border-radius: 50px;
    margin-right: 10px;
  }
  
  .FranchiseList_txnhead__2jJdo h4 {
    text-transform: upperCase;
  }
  
  .FranchiseList_actionbtn__1i6bn span {
    font-size: 16px;
  }
  
  .FranchiseList_editdetails__6WEdt {
    width: 100%;
    float: left;
    text-align: right;
  }
  
  .FranchiseList_icoplus__2EMft {
    margin-top: 8px;
    display: inline-block;
    cursor: pointer;
  }
  
  .FranchiseList_offertaskpop__1vqNB .FranchiseList_modal-body__3DJrN {
    padding: 20px;
  }
  
  ul.FranchiseList_propertyinfos__3hG2l {
    margin: 0;
    padding: 0;
    /* background-color: #f5f5f5; */
    padding: 15px;
    border-radius: 4px;
  }
  
  ul.FranchiseList_propertyinfos__3hG2l select {
    height: 34px;
    line-height: 34px;
    width: 100%;
    border: 1px solid #dbdada;
  }
  
  ul.FranchiseList_propertyinfos__3hG2l span {
    font-weight: 600;
    font-size: 18px;
    margin-left: 40px;
  }
  
  ul.FranchiseList_propertyinfos__3hG2l li {
    font-size: 18px;
    font-weight: 600;
  }
  
  .FranchiseList_proptyinfdropdwn__27oHy {
    display: flex;
    margin-bottom: 15px;
  }
  .FranchiseList_documenDetails__2hk5c {
    width: 100%;
    float: left;
  }
  
  .FranchiseList_submitml1__3-et2 {
    margin-left: 10px !important;
  }
  
  .FranchiseList_form-control__1YVlY {
    font-family: system-ui, sans-serif;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    grid-gap: 0.5em;
    gap: 0.5em;
  }
  
  .FranchiseList_form-control__1YVlY + .FranchiseList_form-control__1YVlY {
    margin-top: 1em;
  }
  
  .FranchiseList_form-control__1YVlY:focus-within {
    color: var(--form-control-color);
  }
  
  input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    /* Not removed via appearance */
    margin: 0;
  
    font: inherit;
    color: #1890ff;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid #1890ff;
    border-radius: 50%;
    transform: translateY(-0.075em);
  
    display: inline-grid;
    place-content: center;
  }
  
  input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: #1890ff;
  }
  
  input[type="radio"]:checked::before {
    transform: scale(1);
  }
  
  input[type="radio"]:focus {
    outline: max(2px, 0.15em) solid #1890ff;
    outline-offset: max(2px, 0.15em);
  }
  
  .FranchiseList_ctm-radiobtn__3H36I {
    display: flex;
  }
  
  span.FranchiseList_mrgnlft__3Kt_P {
    margin-left: 5px !important;
  }
  
  .FranchiseList_itemdelte__13AqH {
    position: absolute;
    top: 0;
    right: 0;
  }
  
  .FranchiseList_photodelt__YnXG1 {
    /* width: 18px;
    height: 18px; */
    cursor: pointer;
    color: rgb(223 8 28);
    fill: rgb(223 8 28);
    /* position: absolute;
    top: 0px;
    right: 100px; */
  }
  
  .FranchiseList_phototile__nkAza {
    text-transform: Capitalize;
    font-size: 16px;
    font-weight: 500;
    width: 102px;
    word-break: break-all;
  }
  
  .FranchiseList_radiodropd__3rm8B {
    margin-block-end: 20px;
  }
  
  .FranchiseList_topmenu__2KAqD {
    font-size: 10px;
  }
  
  label {
    font-size: 16px;
  }
  
  .FranchiseList_ant-list-items__1AS2n {
    background-color: black !important;
  }
  
  .FranchiseList_ant-collapse-header__2tPge .FranchiseList_headerText__2sDuh {
    color: #000 !important;
  }
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: hsla(209, 41%, 70%, 1);
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: hsla(208, 90%, 30%, 1);
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(8, 81, 145, 0.692);
  }
  
  /* Adding media query for small Screen*/
  
  .FranchiseList_clientSectionContainer__2hhLa {
    padding: 1rem 0rem 0rem 0rem;
    border-radius: 8px;
    height: calc(100vh - 7rem);
  /* background-color: #0077d8; */
    transition: 0.3s;
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    width: "100%";
  
  }
  
  @media only screen and (max-device-width: 480px) {
    .FranchiseList_notesmodal___NQm_{
      width:420px
     }
     .FranchiseList_clientSectionContainer__2hhLa {
      padding: 1rem 0rem 0rem 0rem;
      border-radius: 8px;
      height: calc(100vh - 9rem);
    /* background-color: #0077d8; */
      transition: 0.3s;
      display: flex;
      grid-gap: 5px;
      gap: 5px;
      width: "100%";
    
    }
    .FranchiseList_documentlistname__1Qkpp{
      font-size: 12px;
    }
    .FranchiseList_plusicon__2F2fo{
     
       position: absolute;
        right: 10px;
      
    }
    .FranchiseList_allClientsList__1ubXG {
      color: red;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      background-color: red;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      height: calc(100vh - 195px);
      padding: 0.5rem;
      margin-bottom: 25px;
    }
  }
  allClientlistList {
    overflow-y: "auto";
    overflow-x: "hidden";
    height: calc(100vh - 310px);
  }
  allClientlistListWithoutInput {
    overflow-y: "auto";
    overflow-x: "hidden";
    height: calc(100vh - 260px);
  }
  allClientlistList {
    overflow-y: "auto";
    overflow-x: "hidden";
    height: calc(100vh - 310px);
  }
  
  .FranchiseList_dateContainer__39bUp {
    font-weight: bold;
  }
  
  .FranchiseList_deleteIcon__1Be9S {
    font-size: 20px;
   padding-top: -5px;
    cursor: pointer;
  }
  
  .FranchiseList_documentTable__2aQCh {
    /* border-collapse: collapse; */
    width: 100%;
   margin-left: 15px;
   color: grey;
  }
  
  .FranchiseList_documentTable__2aQCh td {
    padding: 2px;
    width: 5px;
    border:none;
    vertical-align: center;
  }
  .FranchiseList_documentTable__2aQCh tr:hover {
    color: black;
    background-color: #92c9f5;
  }
  
  @media only screen and (min-device-width: 800px) and (max-device-width: 1100px) {
    .FranchiseList_brokerageLoading__2wwQU{
      margin-top: -95%;
    }
  }

  @media only screen and (min-device-width: 500px) and (max-device-width: 600px) {
    .FranchiseList_brokerageLoading__2wwQU{
      margin-top: -35%;
    }
  }

  @media only screen and (min-device-width: 1500px){
    .FranchiseList_brokerageLoading__2wwQU{
      margin-top: -95%;
    }
  }
  
@media screen and (max-width: 900px) {
    .Calendar_resetBtn__1xOKy {
        width: 110px !important;
        height: 30px !important;
        box-sizing: border-box !important;
        padding: 0px !important;
        margin-left: 50px !important;
        margin-top: 3px !important;
        text-align: center !important;
        font-size: 5px !important;
        font-weight: 100;
      }
}

@media screen and (max-width: 680px){
    .Calendar_resetBtn__1xOKy {
        position: relative;
        top: 17px;
        left: -120%;
        width: 110px !important;
        height: 30px !important;
        box-sizing: border-box !important;
        padding: 0px !important;
        margin-left: 30px !important;
        margin-top: 3px !important;
        text-align: center !important;
        font-size: 5px !important;
        font-weight: 100;
      }
}

@media screen and (max-width: 500px){
    .Calendar_resetBtn__1xOKy {
        position: relative;
        top: 17px;
        left: -110%;
        width: 110px !important;
        height: 30px !important;
        box-sizing: border-box !important;
        padding: 0px !important;
        margin-left: 30px !important;
        margin-top: 3px !important;
        text-align: center !important;
        font-size: 5px !important;
        font-weight: 100;
      }
}
@media screen and (max-width: 350px){
    .Calendar_resetBtn__1xOKy {
        position: relative;
        top: 17px;
        left: -100%;
        width: 110px !important;
        height: 30px !important;
        box-sizing: border-box !important;
        padding: 0px !important;
        margin-left: 30px !important;
        margin-top: 3px !important;
        text-align: center !important;
        font-size: 5px !important;
        font-weight: 100;
      }
}

@media (min-width: 801px) {
  .Calendar_visibilityComponent__toRat {
    visibility: hidden !important;
  }
  .Calendar_visibilityComponentForSmallScreen__1eIkU {
    display: block;
  }
  .Calendar_visibilityComponentText__1RHcb {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    visibility: visible !important;
  }

  .Calendar_calendarTool__J8t1v {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
  }
}
@media (max-width: 800px) {
  .Calendar_visibilityComponent__toRat {
    visibility: visible !important;
  }

  .Calendar_visibilityComponentForSmallScreen__1eIkU {
    display: none;
  }

  .Calendar_visibilityComponentText__1RHcb {
    /* visibility: hidden !important; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    visibility: visible !important;
  }
  .Calendar_calendarTool__J8t1v {
    display: grid;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
  }
}

.Calendar_hoverEffect__2cO8E {
  background-color: rgb(11 123 203 / 85%);
  color: white;
}


.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  height: 5em !important;
}

.fc-popover{
  position: absolute;
  z-index: 20 !important;
}

.fc-popover-body {
    max-height: 70svh;
    overflow-y: auto;
}

.EventDetailModal .ant-modal-close-x {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Customize Tooltip background color */
.customTooltip .ant-tooltip-inner {
  background-color: #FAFAD2 !important; /* Green background */
  border: 1px solid rgb(216, 216, 216);
}

@media (min-width: 800px) {
  .rbc-toolbar {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .bm-burger-button {
    position: fixed !important;
    width: 36px !important;
    height: 30px !important;
    left: 140px !important;
    top: 50px !important;
  }
}
@media (max-width: 801px) {
  .rbc-toolbar {
    display: grid;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 15px;
  top: 15px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 250px !important;
  top: 0px !important;
}

/* General sidebar styles */
.bm-menu {
  background: #fdfdfd;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}
.bm-menu a {
  color: #b8b7ad;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0px !important;
}

/* calender design */

.rbc-header {
  white-space: normal !important;
  padding: 0 0 !important;
  /* border: 1px solid #474747 !important; */
}

.rbc-header > button {
  background: #ffffff;
  /* background: #373a47; */
  font-weight: 800;
  border: none;
}

.rbc-timeslot-group {
  /* border-bottom: 2px solid #474747 !important; */
}

.rbc-time-content {
  /* border-top: 2px solid #474747 !important; */
}

.rbc-time-content > * + * > * {
  /* border-left: 1px solid #474747 !important; */
}

.rbc-day-slot .rbc-time-slot {
  /* border-top: 1px solid #474747 !important; */
}
.rbc-time-header-content {
  /* border: 1px solid #474747 !important; */
}
.rbc-day-bg + .rbc-day-bg {
  /* border-top: 1px solid #474747 !important; */
}

/* .rbc-today {
  background-color: #474747 !important;
}

.rbc-time-header-cell > .rbc-today > button {
  background-color: #5c1515 !important;
} */

.rbc-day-bg + .rbc-day-bg {
  /* border-left: 1px solid #474747 !important; */
}

.rbc-date-cell {
  color: #0089ff !important;
}
.rbc-off-range {
  color: #999999 !important;
}

/* .rbc-button-link{
  pointer-events: none;
} */

.rbc-time-view {
  width: 100% !important;
  overflow-x: scroll !important;
}

.rbc-time-header {
  width: 137% !important;
  /* overflow: hidden !important; */
}

.rbc-time-content {
  width: 137% !important;
  overflow: visible !important;
}

.rbc-allday-cell {
  display: none !important;
}

:root {
  --height-of-slot: 10vh;
  --width-of-slot: 20vw;
}

.rbc-time-gutter {
  position: fixed;
  top: 81px;
  left: 30px;
  margin-bottom: 50px;
  z-index: 18;
  height: 88vh !important;
  min-height: 0;
  flex-flow: column nowrap;
  background-color: #fcfdff;
  overflow-y: scroll !important;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}
.rbc-time-header-cell {
  position: fixed;
  top: 51px;
  left: 163px;
  overflow-x: scroll !important;
  min-width: 88vw important;
  flex-flow: row nowrap;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}
.rbc-header {
  min-width: var(--width-of-slot);
}
.rbc-time-view {
  margin-top: 30px;
}
.rbc-time-gutter::-webkit-scrollbar,
.rbc-time-header-cell::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.rbc-timeslot-group {
  min-height: var(--height-of-slot) !important;
}

.rbc-time-gutter > .rbc-timeslot-group {
  border: none;
}
.rbc-time-gutter > .rbc-timeslot-group > .rbc-time-slot {
  min-height: var(--height-of-slot) !important;
}
.rbc-time-header-gutter {
  position: fixed;
  top: 5;
  background-color: #fcfdff;
}
.rbc-header {
  min-width: var(--width-of-slot);
}
.rbc-day-slot .rbc-time-slot {
  min-width: var(--width-of-slot);
}

.fc-timegrid-slot {
  height: 1px !important;
  border-bottom: 0 !important;
}
.fc-agenda-slots td div {
  height: 20px;
}
.fc-timegrid-slot-minor {
  line-height: 0.8;
}

@media screen and (max-width:400px) {
    .fc .fc-toolbar-title{
        font-size: 1rem !important;
    }

    .fc .fc-button .fc-icon{
      position: relative;
      top: -5px;
    }

    .fc-timegrid-event .fc-event-main{
        height: 5px !important;
        padding: 0% !important;
    }
    .fc-v-event .fc-event-main {
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
      }
}

@media screen and (max-width: 680px) {
    .fc .fc-toolbar-title{
        font-size: 1rem !important;
    }

    .fc .fc-button .fc-icon{
      position: relative;
      top: -5px;
    }

    .fc-timegrid-event .fc-event-main{
        height: 5px !important;
        padding: 0% !important;
    }
    .fc-v-event .fc-event-main {
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
      }
    }

@media print {

  .CustomCalendar_calendarWrapperContainer__pz6B_ .CustomCalendar_calendarWrapper__2dRwK .CustomCalendar_printTitleAndPropertyAddressCont__2uUVm {
    display: block !important;
    text-align: center !important;
  }
  .CustomCalendar_calendarWrapperContainer__pz6B_ .CustomCalendar_calendarWrapper__2dRwK .CustomCalendar_calendar__3XxKO {
    margin-bottom: 10px !important;
  }

  .CustomCalendar_calendarWrapperContainer__pz6B_ .CustomCalendar_calendarWrapper__2dRwK .CustomCalendar_calendar__3XxKO .CustomCalendar_header__2BOJS {
    padding: 5px 0 !important;
  }

  .CustomCalendar_calendarWrapperContainer__pz6B_ .CustomCalendar_calendarWrapper__2dRwK .CustomCalendar_calendar__3XxKO .CustomCalendar_header__2BOJS .CustomCalendar_headerTools__Pt2Eq .CustomCalendar_arrow__3b-Mz {
    display: none;
  }

  .CustomCalendar_calendarWrapperContainer__pz6B_ .CustomCalendar_calendarWrapper__2dRwK .CustomCalendar_calendar__3XxKO .CustomCalendar_weekdays__1tqeB {
    display: flex !important;
  }

  .CustomCalendar_calendarWrapperContainer__pz6B_ .CustomCalendar_calendarWrapper__2dRwK .CustomCalendar_calendar__3XxKO .CustomCalendar_weekday__2SyrW,
  .CustomCalendar_calendarWrapperContainer__pz6B_ .CustomCalendar_calendarWrapper__2dRwK .CustomCalendar_calendar__3XxKO .CustomCalendar_empty__3r-wr,
  .CustomCalendar_calendarWrapperContainer__pz6B_ .CustomCalendar_calendarWrapper__2dRwK .CustomCalendar_calendar__3XxKO .CustomCalendar_day__2DzFg {
    width: calc(100% / 7) !important;
  }

  .CustomCalendar_calendarWrapperContainer__pz6B_ .CustomCalendar_calendarWrapper__2dRwK .CustomCalendar_calendar__3XxKO .CustomCalendar_daysGrid__1X3Zg {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .CustomCalendar_calendarWrapperContainer__pz6B_ .CustomCalendar_viewModeOptions__2fYiF, 
  .CustomCalendar_calendarWrapperContainer__pz6B_ .CustomCalendar_viewModeOptions__2fYiF .CustomCalendar_hideOnPrint__39PCe,
  .CustomCalendar_printBtn__HrSQi,
  .CustomCalendar_shareBtn__235IX {
    display: none !important;
  }
  .CustomCalendar_noPrint__2gDHC {
    display: none !important;
  }
  .CustomCalendar_day__2DzFg {
    /* break-inside: avoid; */
  }

  .CustomCalendar_day__2DzFg.CustomCalendar_today__2W9IB {
    background-color: unset !important;
  }

  .CustomCalendar_calendarWrapperContainer__pz6B_ .CustomCalendar_calendarWrapper__2dRwK .CustomCalendar_calendar__3XxKO .CustomCalendar_eventContainer__2d9ZN .CustomCalendar_eventTitle__28mA9 .CustomCalendar_homeEvent__tfamg{
    line-height: normal;
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
  }
}

.CustomCalendar_taskTitle__2AI1C{
  width:100%;
  justify-content: center;
  font-weight: bold;
  display: flex;
}

.CustomCalendar_cardFullAddress__3_fAm{
  font-weight: 700;
  color: rgb(82, 82, 82); 
  cursor: pointer
}

.CustomCalendar_cardFullAddress__3_fAm:hover{
  color: black;
}

.CustomCalendar_cardFullAddressHyperLink__1DVC2{
  font-weight: 700;
  color: blue; 
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 1.5px;
}
.CustomCalendar_cardFullAddressHyperLink__1DVC2:hover{
  color: #551A8B;
}
.CustomCalendar_hoverTaskLabel__3xkT-{
  font-weight: bold;
  min-width: 60px;
  color:rgb(82, 82, 82);
}

.CustomCalendar_calendarWrapperContainer__pz6B_ {
  width: 100%;
  position: relative;
  /* margin: 0 5px; */
}

.CustomCalendar_calendarWrapperContainer__pz6B_ .CustomCalendar_calendarWrapper__2dRwK .CustomCalendar_printTitleAndPropertyAddressCont__2uUVm {
  display: none;
}

.CustomCalendar_calendar__3XxKO {
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
}

.CustomCalendar_header__2BOJS {
  text-align: center;
  /* font-weight: bold; */
  margin-bottom: 10px;
  background-color: #ffffff;
  /* color: aliceblue; */
  color: #1890FF;
  font-size: 1.75em;
  font-weight: 500;
}

.CustomCalendar_header__2BOJS .CustomCalendar_headerTools__Pt2Eq {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.CustomCalendar_centerLoader__2min_ {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.CustomCalendar_header__2BOJS .CustomCalendar_headerTools__Pt2Eq .CustomCalendar_monthTitle__1flOs {
  min-width: 200px;
  margin-left: -38px;
  
}

.CustomCalendar_header__2BOJS .CustomCalendar_headerTools__Pt2Eq .CustomCalendar_arrow__3b-Mz {
  cursor: pointer;
}

.CustomCalendar_calendar__3XxKO .CustomCalendar_parentGridOutline__3JExG {
  border: 1px solid silver;
}

.CustomCalendar_weekdays__1tqeB {
  display: flex;
}

.CustomCalendar_weekday__2SyrW {
  border: 1px solid silver;
  border-spacing: 0;
  color: #178DFA;
  font-weight: bold;
  background-color: white;
}

.CustomCalendar_weekday__2SyrW,
.CustomCalendar_empty__3r-wr {
  width: calc(100% / 7);
  text-align: center;
}

.CustomCalendar_daysGrid__1X3Zg {
  display: flex;
  flex-wrap: wrap;
  /* background-color: #d9d9d99a; /* Make greyish background for days not in month (after days) */
}

.CustomCalendar_day__2DzFg {
  width: calc(100% / 7);
  border: 1px solid silver;
  border-spacing: 0;
  padding: 5px;
  min-height: 40px;
  background-color: white;
}

.CustomCalendar_day__2DzFg:hover {
  background-color: aliceblue;
}

.CustomCalendar_day__2DzFg.CustomCalendar_today__2W9IB {
  background-color: #fffadf;
}

.CustomCalendar_day__2DzFg .CustomCalendar_dayNumber__2YHcA {
  text-align: top;
  color: #178DFA;
  font-weight: bold;
}

.CustomCalendar_homeDayNumber__oN1C_:hover {
  background-color: rgb(218, 218, 218);
  border-radius: 5px;
  cursor: pointer;
  padding: 0px 10px;
}

.CustomCalendar_empty__3r-wr {
  /* visibility: hidden; */
  width: calc(100% / 7);
  border: 1px solid silver; /* Add grid lines for days not in month */
  background-color: #d9d9d99a; /* Make greyish background for days not in month (Before days) */
  border-spacing: 0;
  padding: 5px;
  min-height: 40px;
}

.CustomCalendar_empty__3r-wr .CustomCalendar_dayNumber__2YHcA {
  text-align: left;
  color: #178DFA;
  font-weight: bold;
}

.CustomCalendar_titleCard__3e-N8{
  border: 0.5px solid rgb(174, 174, 174);
  padding: 1px 2px;
  border-radius: 5px;
}

.CustomCalendar_eventContainer__2d9ZN {
  font-size: small;
}

.CustomCalendar_calendarWrapperContainer__pz6B_ .CustomCalendar_viewModeOptions__2fYiF {
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  padding: 5px;
  background-color: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* border-bottom: 1px solid silver; */
}

.CustomCalendar_eventContainer__2d9ZN .CustomCalendar_homeEvent__tfamg{
  margin-bottom: 2px;
  color: blue;
  line-height: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  cursor: pointer;
}

.CustomCalendar_eventContainer__2d9ZN .CustomCalendar_eventTitle__28mA9 {
  margin-bottom: 2px;
  color: blue;
  line-height: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.CustomCalendar_eventContainer__2d9ZN .CustomCalendar_eventTitle__28mA9:hover {
  cursor: pointer;
  font-weight: bold;
}

.CustomCalendar_eventContainer__2d9ZN .CustomCalendar_eventTitle__28mA9.CustomCalendar_upcomingEvent__1Kvpa{
  color: blue;
}

.CustomCalendar_eventContainer__2d9ZN .CustomCalendar_homeTitle__3o5fu.CustomCalendar_upcomingEvent__1Kvpa{
  color: blue;
}

.CustomCalendar_eventContainer__2d9ZN .CustomCalendar_eventTitle__28mA9.CustomCalendar_closingDate__2b5B4{
  color: black;
  font-weight: bold;
}

.CustomCalendar_eventContainer__2d9ZN .CustomCalendar_homeTitle__3o5fu.CustomCalendar_closingDate__2b5B4{
  color: black;
}

.CustomCalendar_eventContainer__2d9ZN .CustomCalendar_eventTitle__28mA9.CustomCalendar_completedEvent__8030Z{
  color: green;
}

.CustomCalendar_eventContainer__2d9ZN .CustomCalendar_homeTitle__3o5fu.CustomCalendar_completedEvent__8030Z{
  color: green;
}

.CustomCalendar_eventContainer__2d9ZN .CustomCalendar_eventTitle__28mA9.CustomCalendar_incompleteEvent__1WyRa{
  color: red;
}

.CustomCalendar_eventContainer__2d9ZN .CustomCalendar_homeTitle__3o5fu.CustomCalendar_incompleteEvent__1WyRa{
  color: red;
}

.CustomCalendar_eventContainer__2d9ZN .CustomCalendar_eventTitle__28mA9.CustomCalendar_holiday__2n3rn {
  color: purple;
  font-weight: 600;
}

.CustomCalendar_eventContainer__2d9ZN .CustomCalendar_homeTitle__3o5fu.CustomCalendar_holiday__2n3rn{
  color: purple;
  font-weight: 600;
}

.CustomCalendar_seeMore__3QaJp {
  cursor: pointer;
  color: orangered;
}

.CustomCalendar_homeTitle__3o5fu:hover {
  font-weight: 600;
}

.CustomCalendar_calendarWrapperContainer__pz6B_ .CustomCalendar_printBtn__HrSQi {
  /* position: sticky;
  top: 0;
  right: 10px;
  left: 93%;
  margin-left: 5px; */
}

@media screen and (min-width: 551px) and (max-width: 850px) {
  .CustomCalendar_calendar__3XxKO {
    width: 90%;
  }
 
  .CustomCalendar_header__2BOJS .CustomCalendar_headerTools__Pt2Eq .CustomCalendar_arrow__3b-Mz {
    font-size: small;
  }
}

@media screen and (max-width: 480px) {
  .CustomCalendar_calendarWrapperContainer__pz6B_ .CustomCalendar_printBtn__HrSQi {
    display: none;
  }
 
  .CustomCalendar_header__2BOJS .CustomCalendar_headerTools__Pt2Eq .CustomCalendar_arrow__3b-Mz {
    font-size: small;
  }
}

@media screen and (max-width: 550px) {
  .CustomCalendar_calendarWrapperContainer__pz6B_ {
    zoom: 0.7;
  }
  .CustomCalendar_calendar__3XxKO {
    width: 98%;
  }
  
  .CustomCalendar_calendarWrapperContainer__pz6B_ .CustomCalendar_shareBtnInWebView__3GloX {
    font-size: smaller;
  }
  .CustomCalendar_header__2BOJS .CustomCalendar_headerTools__Pt2Eq .CustomCalendar_arrow__3b-Mz {
    font-size: small;
  }
}

.EventDetailModal_eventName__2j6yd {
    font-size: 15px;
    text-align: center;
}

.EventDetailModal_modalDateTitle__2Mf31 {
    text-align: center;
    color: #178DFA;
    text-transform: uppercase;
}

.EventDetailModal_mainDetailContainer__3tMNu {
    background-color: #60bf40;
    color: aliceblue;
    padding: 10px;
    border-radius: 10px;
}

.EventDetailModal_fullAddress__cPrCg {
    margin-top:-5px;
    color: #178DFA;
    font-weight:bold;
    font-size: 18px;
    text-align: center;
    cursor: default;
}

/* .fullAddress:hover{
    color: black;
} */

.EventDetailModal_modalFullAddressHyperLink__34sJt{
    margin-top:-5px;
    font-weight:bold;
    font-size: 18px;
    text-align: center;
    color: blue; 
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 1.5px;
  }
  .EventDetailModal_modalFullAddressHyperLink__34sJt:hover{
    color: #551A8B;
  }

.EventDetailModal_modalContents__3Z66I {
    font-weight: bold;
    color: rgba(11, 123, 203);
    margin-bottom: 5px;
}

.EventDetailModal_eventDates__2Q3yb {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 550px) {

    .EventDetailModal_modalDateTitle__2Mf31,
    .EventDetailModal_modalContents__3Z66I {
        font-size: small;
    }

    .EventDetailModal_fullAddress__cPrCg,
    .EventDetailModal_modalFullAddressHyperLink__34sJt {
        font-size: medium;
       
    }

    .EventDetailModal_eventName__2j6yd {
        font-size: smaller; 
    }

    .EventDetailModal_description__2y4gB {
        font-size: smaller;
    }
}

.FilterModal_agentCard__3t0zk {
    border: 1px solid rgb(198, 198, 198); 
    border-radius: 2px;
    padding: 3px 7px;
    cursor: pointer;
}

.FilterModal_agentCard__3t0zk div{
    white-space: nowrap;
    overflow: hidden;  
    text-overflow: ellipsis;
}

.FilterModal_agentCard__3t0zk:hover {
    background-color: rgb(228, 231, 234);
}

.FilterModal_agentCardParent__1hehP {
    height: 330px;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    overflow-y: auto;
    color: rgb(84, 84, 84);
    padding-right: 5px;
}

.FilterModal_agentCardParent__1hehP::-webkit-scrollbar {
    width: 7px; /* Adjust scrollbar width */
}

.FilterModal_filterTitle__1e9tQ{
    display: flex; 
    justify-content: center;
    padding: 0px;
    color: rgba(16, 138, 252, 0.989); 
    text-decoration: underline;
    text-underline-offset: 4px;
}

@media screen and (max-width: 550px) {

    .FilterModal_modalDateTitle__3sLWV,
    .FilterModal_modalContents__xHZ51 {
        font-size: small;
    }

    .FilterModal_fullAddress__24Sc4 {
        font-size: medium;
       
    }

    .FilterModal_eventName__11G8J {
        font-size: smaller; 
    }

    .FilterModal_description__3uyPy {
        font-size: smaller;
    }
}
.RtdRow_signOfferConfirmationBtns__1u03f {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  font-size: 5px;
  margin: 50px 20px 20px 20px;
  text-align: center;
}

.RtdRow_signOfferConfirmationMsgDiv__12Dyx {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  height: 80%;
  font-family: Arial, Helvetica, sans-serif;
}

.RtdRow_eachDocumentRowContainer__3Yw7l {
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    align-self: center;
    border-style: solid;
    border-width: 0 8px 0 8px;
    border-right-color: #ffce51;
    border-left-color: #3376a8;

    /* style={{
        position: "sticky",
        borderStyle: "solid",
        borderRightWidth: 8,
        borderLeftWidth: 8,
        borderBottomWidth: 0,
        borderTopWidth: 0,
        borderRightColor: "#ffce51",
        borderLeftColor: "#3376a8",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignSelf: "center",
      }} */
}

.RtdRow_sellerCompContainer__2vaRg {
    /* flex: 1; */
    justify-content: space-between;
    text-align: center;
    align-items: center;
    width: 100%;

    /* style={{
        flex: 1,
        justifyContent: "space-between",
        textAlign: "center",
        alignItems: "center",
        marginBottom: 0,
      }} */
}
.RtdRow_sellerCompContainer__2vaRg .RtdRow_sellerCenterline__3Pndb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    /* style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 17,
      }} */
}

.RtdRow_buyerCompContainer__2vlPp {
    /* flex: 1; */
    justify-content: space-between;
    text-align: center;
    width: 100%;
}

.RtdRow_buyerCompContainer__2vlPp .RtdRow_buyerCenterLine__118JF {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.RtdRow_signalIconOutline__2Ocjv {
    width: 33px;
    height: 30px;
    border-radius: 4px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.RtdRow_signalIndicator__3H9gm {
    width: 19px;
    height: 19px;
    position: relative;
    border-radius: 9px;
    overflow: hidden;
}

.RtdRow_fullIndicator__2iYsp {
    width: 19px;
    height: 19px;
    position: relative;
    border-radius: 9px;
    overflow: hidden;
    background-color: var(--fullColor);
}

.RtdRow_signalIndicator__3H9gm::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 50%;
    top: 0;
    left: 0;
    background-color: var(--topColor);
}

.RtdRow_signalIndicator__3H9gm::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 50%;
    border-top: 1.6px solid rgb(42, 42, 42);
    bottom: 0;
    left: 0;
    background-color: var(--bottomColor);
}


.RtdRow_dateAndTimeSeller__3pi_6 {
    font-size: 10px;
    text-align: left;
    padding: 5px 0 0 5px;
    color: #292929;
}
.RtdRow_newdateSeller__2QdTr{
    text-align: left;
    margin-left: 10px;
   font-size:13px;
    font-weight:bolder
  
  }
  .RtdRow_newtimeSeller__eNmui{
   text-align: center;
    margin-left: 10px;
   font-size:13px;
   margin-top: -16px;
  font-weight:bolder;
  margin-bottom: 0,
}
.RtdRow_newdateBuyer__1Mhqe{
text-align:end;
margin-right: 10px;
font-size:13px;
font-weight:bolder
}
.RtdRow_newtimeBuyer__3j7UE{
   
text-align: right;
margin-right: 20px;
margin-top: -18px;
font-size:13px;
font-weight:bolder;
margin-bottom: 0,
      }
.RtdRow_dateAndTimeBuyer__mIdXz {
    font-size: 10px;
    text-align: right;
    padding: 5px 5px 0 0;
    color: #292929;
}
.RtdRow_documentDetailOuterCOntainer__2kPQD {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 100px;
}
.RtdRow_documentDetailOuterCOntainer__2kPQD .RtdRow_documentBox__2zAee {
    /* width: 310px; */
    width: 100%;
    min-height: 50px;
    border: 2px solid;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 2px;
    gap: 2px;
    color: #8e8e8e;
    font-weight: 200;
    margin: 4px 0 4px 0;
    text-align: center;
    padding: 2px;
   }

.RtdRow_documentNameContainer__17JrD {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    /* word-break: break-all; */
}

.RtdRow_documentName__2y8b_ {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.RtdRow_documentName__2y8b_:hover{
    color: rgb(8,81,145);
}

@media screen and (min-width: 550px) and (max-width: 720px) {
    .RtdRow_documentName__2y8b_.RtdRow_singleWord__2KxY6 {
        max-width: 60px;
    }
    .RtdRow_newdateSeller__2QdTr{
        margin-left: 5px;
      font-size:11px;
    }
    .RtdRow_newtimeSeller__eNmui{
       margin-left: 5px;
        font-size:11px;
     }
     .RtdRow_newdateBuyer__1Mhqe{
         margin-right: 10px;
        font-size:11px;
     }
        .RtdRow_newtimeBuyer__3j7UE{
        margin-right: 20px;
        margin-top: -18px;
        font-size:11px;
       }
}
@media screen and (min-width: 850px) and (max-width: 950px) {
    .RtdRow_documentName__2y8b_.RtdRow_singleWord__2KxY6 {
        max-width: 70px;
    }
    .RtdRow_documentName__2y8b_ {
        font-size: 12px;
    }
}
@media screen and (min-width: 950px) and (max-width: 1200px) {
    .RtdRow_documentName__2y8b_.RtdRow_singleWord__2KxY6 {
        max-width: 100px;
    }
}
@media screen and (max-width: 410px) {
    .RtdRow_documentName__2y8b_.RtdRow_singleWord__2KxY6 {
        max-width: 60px;
    }
    .RtdRow_sellerCompContainer__2vaRg {
        /* flex: 1; */
        justify-content: space-between;
        text-align: center;
        align-items: center;
        width: 85%;
    
    }
    .RtdRow_signalIconOutline__2Ocjv {
        width: 18px;
        height: 20px;
        border-radius: 2px;

    }
    .RtdRow_fullIndicator__2iYsp {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        /* position: relative;
       
        overflow: hidden;
        background-color: var(--fullColor); */
    }
    .RtdRow_signalIconOutline__2Ocjv .RtdRow_signalIndicator__3H9gm {
        width: 10px;
        height: 10px;
        border-radius: 5px;

    }
    .RtdRow_newdateSeller__2QdTr{
        margin-left: 5px;
      font-size:10px;
    }
    .RtdRow_newtimeSeller__eNmui{
        margin-left: 5px;
      font-size:10px;
     
    }
    .RtdRow_newdateBuyer__1Mhqe{
        margin-right: 10px;
       font-size:10px;
    }
       .RtdRow_newtimeBuyer__3j7UE{
       margin-right: 20px;
       margin-top: -16px;
       font-size:10px;
      }
}


@media screen and (min-width: 551px) and (max-width: 850px) {

    .RtdRow_dateAndTimeSeller__3pi_6, .RtdRow_dateAndTimeBuyer__mIdXz {
        font-size: 8px;
    }

    .RtdRow_documentDetailOuterCOntainer__2kPQD .RtdRow_documentBox__2zAee {
        /* width: 200px; */
        font-size: 12px;
        font-weight: 200;
        width: 100%;
        min-height: 40px;
    }
    .RtdRow_documentName__2y8b_ {
        font-size: 12px;
    }
}



@media screen and (min-width: 413px) and (max-width: 550px) {
    
    .RtdRow_signalIconOutline__2Ocjv {
        width: 18px;
        height: 21px;
        border-radius: 2px;

    }
    .RtdRow_fullIndicator__2iYsp {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        /* position: relative;
       
        overflow: hidden;
        background-color: var(--fullColor); */
    }
    .RtdRow_signalIconOutline__2Ocjv .RtdRow_signalIndicator__3H9gm {
        width: 12px;
        height: 12px;
        border-radius: 6px;

    }

    .RtdRow_dateAndTimeSeller__3pi_6, .RtdRow_dateAndTimeBuyer__mIdXz {
        font-size: 8px;
    }

    .RtdRow_documentDetailOuterCOntainer__2kPQD .RtdRow_documentBox__2zAee {
        /* width: 120px; */
        font-size: 10px;
        font-weight: 200;
        width: 100%;
        min-height: 35px;

    }
    .RtdRow_documentName__2y8b_ {
        font-size: 12px;
    }
    
    .RtdRow_signOfferConfirmationBtns__1u03f {
      flex-direction: column;
        }
    
    .RtdRow_signOfferConfirmationMsgDiv__12Dyx {
        text-align: start;
    }
    

}

.RtdWindow_preRTDLayoutOuterContainer__3r7HX {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: auto;
    text-align: center;
    /* outline: auto; */

}

.RtdWindow_propertyDetails__2Vc-w {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    max-width: 80%;
    border-radius: 8px;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.RtdWindow_addressDiv__1U-Zg {
    /* outline: auto; */
    font-weight: bold;
    color: grey;
    font-size: large;
}
.RtdWindow_sendEmail__18BjX {
    font-weight: bold;
    color: grey;
    font-size: 19px;
    margin-top:20px;
    margin-left: 20px;
    cursor:pointer
}
.RtdWindow_buyerSellerBox__1pkso {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.RtdWindow_buyerSellerBox__1pkso .RtdWindow_sellerContainer__1W5ss {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 2px;
    gap: 2px;
}
.RtdWindow_buyerSellerBox__1pkso .RtdWindow_sellerContainer__1W5ss .RtdWindow_sellerDesk__2SwDs {
    background: #2976be;
    width: 100%;
    padding: 5px;
    border-radius: 5px 5px 5px 0;
    color: white;
    font-weight: bold;
    font-family: sans-serif;
}

.RtdWindow_buyerSellerBox__1pkso .RtdWindow_buyerContainer__31jyG {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 2px;
    gap: 2px;

}

.RtdWindow_buyerSellerBox__1pkso .RtdWindow_buyerContainer__31jyG .RtdWindow_buyerDesk__1tfB3 {
    background: #ffce51;
    width: 100%;
    padding: 5px;
    border-radius: 5px 5px 0 5px;
    font-weight: bold;
    font-family: sans-serif;
}

.RtdWindow_agentDetailsModalContent__1YoVN {
    display: flex;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
    color: grey;
    margin-bottom: 40px;
}
.RtdWindow_agentDetailsModalContent__1YoVN .RtdWindow_userDetails__1rpk_ {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}
.RtdWindow_agentDetailsModalContent__1YoVN .RtdWindow_userDetails__1rpk_ .RtdWindow_userDetailsFlex__1b3Bp {
    width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}
.RtdWindow_preRTDDocOuterContainer__2TkqX {
    display: flex;
    flex-direction: column;
    height: 50vh;
}
.RtdWindow_preRTDDocOuterContainer__2TkqX ::-webkit-scrollbar {
    display: none !important;
}
.RtdWindow_preRTDDocOuterContainer__2TkqX .RtdWindow_documentContainer__hiYf_ {
    overflow-y: auto;
    min-height: 40vh;
}
.RtdWindow_preRTDDocOuterContainer__2TkqX .RtdWindow_sharedFolderBtn__1Wonk {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    width: 70%;
    margin: auto;
    border: 2px solid;
    border-radius: 16px;
    color: #096dd9;
    border-color: #2976be;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    background-color: aliceblue;
   

}
.RtdWindow_trnxnDiv__2vtgo {
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    outline: auto;
}

.RtdWindow_sharedDocModalContents__1i4Wl {
    height: 60vh;
}

.RtdWindow_sharedDocHeading__20fP5 {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    color: #096dd9;
    font-size: 20px;
    font-weight: 500;
}

.RtdWindow_sharedDocModalContents__1i4Wl .RtdWindow_tableOuterScroll__3CrPs {
    height: 50vh;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0 10px;

}
.RtdWindow_sharedDocModalContents__1i4Wl .RtdWindow_tableOuterContainer__2GfaM {
    /* padding: 10px; */
    min-width: 270px;


}
.RtdWindow_sharedDocModalContents__1i4Wl .RtdWindow_spinner__oAJhY {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.RtdWindow_sharedDocModalContents__1i4Wl .RtdWindow_sharedFolderTable__gxLaL {
    width: 100%;
    position: relative;
}
.RtdWindow_sharedDocModalContents__1i4Wl .RtdWindow_sharedFolderTable__gxLaL thead{
    background-color: #2171b9;
    color: white;
    text-align: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    font-size: 16px;
    outline: auto;
}
.RtdWindow_sharedDocModalContents__1i4Wl .RtdWindow_sharedFolderTable__gxLaL thead .RtdWindow_FileName__1SLXD {
    text-align: left;
    padding-left: 10px;
}
.RtdWindow_sharedDocModalContents__1i4Wl .RtdWindow_sharedFolderTable__gxLaL td,
.RtdWindow_sharedDocModalContents__1i4Wl .RtdWindow_sharedFolderTable__gxLaL th {
    border: 1px solid #ddd;
}
.RtdWindow_sharedDocModalContents__1i4Wl .RtdWindow_sharedFolderTable__gxLaL .RtdWindow_fileNameColumn__fLeaa {
    color: blue;
    cursor: pointer;
    font-weight: bolder;

}
.RtdWindow_sharedDocModalContents__1i4Wl .RtdWindow_sharedFolderTable__gxLaL .RtdWindow_sharedDate__1INgm {
    color: black;
    font-weight: 500;
    text-align: center;
    white-space: pre-wrap;
}
.RtdWindow_sharedDocModalContents__1i4Wl .RtdWindow_sharedFolderTable__gxLaL .RtdWindow_sharedByName__1wCDo {
    color: grey;
    font-size: 14px;
    font-weight: 400;
    text-align: center;

}





@media screen and (min-width: 551px) and (max-width: 850px) {

    .RtdWindow_preRTDLayoutOuterContainer__3r7HX {
        width: 100%;
    }

    .RtdWindow_addressDiv__1U-Zg {
        font-size: 14px;
    }
    .RtdWindow_sendEmail__18BjX {
        font-weight: 14px;
        color: grey;
        font-size: large;
        margin-top:20px;
        margin-left: 20px;
    }
    .RtdWindow_buyerSellerBox__1pkso .RtdWindow_sellerContainer__1W5ss .RtdWindow_sellerDesk__2SwDs,
    .RtdWindow_buyerSellerBox__1pkso .RtdWindow_buyerContainer__31jyG .RtdWindow_buyerDesk__1tfB3 {
        font-size: 12px;
    }
    .RtdWindow_preRTDDocOuterContainer__2TkqX {
        height: 54vh;
    }
    .RtdWindow_preRTDDocOuterContainer__2TkqX .RtdWindow_documentContainer__hiYf_ {
        min-height: 50vh;
    }
    .RtdWindow_preRTDDocOuterContainer__2TkqX .RtdWindow_sharedFolderBtn__1Wonk {
        border-radius: 14px;
        font-weight: 500;
        font-size: 14px;
        grid-gap: 5px;
        gap: 5px;
    }
    
    .RtdWindow_sharedDocHeading__20fP5 {
        grid-gap: 8px;
        gap: 8px;
        font-size: 16px;
    }
    .RtdWindow_sharedDocModalContents__1i4Wl .RtdWindow_sharedFolderTable__gxLaL .RtdWindow_fileNameColumn__fLeaa,
    .RtdWindow_sharedDocModalContents__1i4Wl .RtdWindow_sharedFolderTable__gxLaL thead {
        font-size: 14px;
    }
    .RtdWindow_sharedDocModalContents__1i4Wl .RtdWindow_sharedFolderTable__gxLaL .RtdWindow_sharedDate__1INgm,
    .RtdWindow_sharedDocModalContents__1i4Wl .RtdWindow_sharedFolderTable__gxLaL .RtdWindow_sharedByName__1wCDo {
        font-size: 12px;
    }

}

@media screen and (max-width: 550px) {

    .RtdWindow_preRTDLayoutOuterContainer__3r7HX {
        width: 100%;
    }

    .RtdWindow_propertyDetails__2Vc-w {
        grid-gap: 5px;
        gap: 5px;
        
    }

    .RtdWindow_addressDiv__1U-Zg {
        font-size: small;
    }
    .RtdWindow_sendEmail__18BjX {
        font-size: small;
        margin-top:20px;
        margin-left: 20px;
    }
    .RtdWindow_buyerSellerBox__1pkso .RtdWindow_sellerContainer__1W5ss .RtdWindow_sellerDesk__2SwDs,
    .RtdWindow_buyerSellerBox__1pkso .RtdWindow_buyerContainer__31jyG .RtdWindow_buyerDesk__1tfB3 {
        font-size: 12px;
    }
     .RtdWindow_preRTDDocOuterContainer__2TkqX {
        height: 58vh;
    }
    .RtdWindow_preRTDDocOuterContainer__2TkqX .RtdWindow_documentContainer__hiYf_ {
        min-height: 51vh;
    }
    .RtdWindow_preRTDDocOuterContainer__2TkqX .RtdWindow_sharedFolderBtn__1Wonk {
        border-radius: 12px;
        font-weight: 500;
        font-size: 12px;
        grid-gap: 5px;
        gap: 5px;
        margin-bottom: 30px;
    }
    .RtdWindow_sharedDocHeading__20fP5 {
        grid-gap: 5px;
        gap: 5px;
        font-size: 14px;
    }
    .RtdWindow_sharedDocModalContents__1i4Wl .RtdWindow_sharedFolderTable__gxLaL .RtdWindow_fileNameColumn__fLeaa,
    .RtdWindow_sharedDocModalContents__1i4Wl .RtdWindow_sharedFolderTable__gxLaL thead,
    .RtdWindow_sharedDocModalContents__1i4Wl .RtdWindow_sharedFolderTable__gxLaL .RtdWindow_sharedDate__1INgm,
    .RtdWindow_sharedDocModalContents__1i4Wl .RtdWindow_sharedFolderTable__gxLaL .RtdWindow_sharedByName__1wCDo {
        font-size: 12px;
    }


}

.showImagePdfModal .ant-modal-body {
    padding: 5px;
}
.showImagePdfModal .ant-modal-close-x{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.iframePreRtd {
    touch-action: manipulation; /* Allow pinch zooming on iOS */
}

@media screen and (max-width: 550px) {
    .showImagePdfModal .ant-modal-body {
        padding: 0px;
    }
    .showImagePdfModal .ant-modal-close-x{
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.DoraDocument_DoraDocumentContainer__29OJw {
    width: 100%;
}
.DoraDocument_DoraDocumentContainer__29OJw .DoraDocument_doraDocHeading__1Kfo6 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.DoraDocument_DoraDocumentContainer__29OJw .DoraDocument_doraDocHeading__1Kfo6>h3 {
    color: #189EFF;
    font-size: 24px;
}
.DoraDocument_legendTableOuterDiv__3YXQ7 {
    margin-top: 20px;
    height: 70vh;
    overflow-y: auto;
}
.DoraDocument_legendTableOuterDiv__3YXQ7>table {
    width: 100%;
    font-weight: 600;
    border-collapse: collapse;
}
.DoraDocument_legendTableOuterDiv__3YXQ7>table th,
.DoraDocument_legendTableOuterDiv__3YXQ7>table td {
    border: .5px solid silver;
    border-collapse: collapse;
    padding: 5px 10px;

}

.DoraDocument_legendTableOuterDiv__3YXQ7>table thead {
    background-color: #3871b9;
    color: white;
    text-align: center;
    font-size: 18px;
}


.DoraDocument_fileContainer__2fOFB:hover{
    color: #6e99d6;
    cursor:pointer;
}


/* legends css starts */
.DoraDocument_tableRow__2uByX {
    height: 50px;
    width: 100%;
    /* border: 2px solid green; */
  }
  
  .DoraDocument_symbolsColumn__1Glm2 {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    /* border: 1px solid red; */
  }
  
  .DoraDocument_infoColumn__3-4uc {
    width: 80%;
    text-align: left;
  }
  
  .DoraDocument_topHalfCircle__2diRE {
    width: 20px;
    height: 10px;
    border-radius: 10px 10px 0 0;
    border: 1px solid black;
    overflow: hidden;
    position: relative;
  }
  
  .DoraDocument_topHalfCircle__2diRE::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 10px;
    border-bottom: 1px solid black;
    border-radius: 10px 10px 0 0;
  }
  
  .DoraDocument_bottomHalfCircle__2U4yO {
    width: 20px;
    height: 10px;
    border-radius: 0 0 10px 10px;
    border: 1px solid black;
    overflow: hidden;
    position: relative;
  }
  
  .DoraDocument_bottomHalfCircle__2U4yO::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 10px;
    border-top: 2px solid black;
    border-radius: 0 0 10px 10px;
  }
  
  .DoraDocument_fullCircle__3bmj3 {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid black;
  }
  
  
   /* legend css ends */

@media screen and (min-width: 551px) and (max-width: 850px) {
    .DoraDocument_DoraDocumentContainer__29OJw .DoraDocument_doraDocHeading__1Kfo6>h3 {
        font-size: 20px;
    }
}

@media screen and (max-width: 550px) {
    .DoraDocument_DoraDocumentContainer__29OJw .DoraDocument_doraDocHeading__1Kfo6>h3 {
        font-size: 18px;
    }
    .DoraDocument_legendTableOuterDiv__3YXQ7>table thead {
        font-size: 14px;
    }

    .DoraDocument_legendTableOuterDiv__3YXQ7>table tbody {
        font-size: 12px;
    }

}

.NavbarForRtd_preRTDNavbarContainer__2j0Dy {
    width: 100%;
    height: 60px;
    background-color: rgb(8, 81, 145);
    padding: 0px 25px;
}

.NavbarForRtd_outerFlexRow__vAUpD {
    height: 100%;
    display: flex;
    color: white;
}

.NavbarForRtd_columnFirst__2URbH {
    display: flex;
    align-items: center;
    /* outline: auto; */
    width: 100%;
}

.NavbarForRtd_columnFirst__2URbH .NavbarForRtd_backBtnBox__12rz5 {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
}

.NavbarForRtd_columnSecond__2jOkm {
    display: flex;
    align-items: center;
    justify-content: center;
    /* outline: auto; */
    width: 100%;
    font-size: 20px;
}

.NavbarForRtd_columnThird__A4B-v {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.NavbarForRtd_columnThird__A4B-v .NavbarForRtd_outerContainer__2FnLn {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    color: #fff;
}

@media screen and (min-width: 551px) and (max-width: 850px) {
    .NavbarForRtd_preRTDNavbarContainer__2j0Dy {
        height: 55px;
        padding: 0px 15px;
    }

    .NavbarForRtd_columnFirst__2URbH .NavbarForRtd_backBtnBox__12rz5 {
        grid-gap: 10px;
        gap: 10px;
    }
    
    .NavbarForRtd_columnSecond__2jOkm {
        font-size: 18px;
    }
}

@media screen and (max-width: 550px) {
    .NavbarForRtd_preRTDNavbarContainer__2j0Dy {
        height: 50px;
        padding: 0px 10px;
    }

    .NavbarForRtd_columnFirst__2URbH .NavbarForRtd_backBtnBox__12rz5 {
        grid-gap: 5px;
        gap: 5px;
    }
    
    .NavbarForRtd_columnSecond__2jOkm {
        font-size: 12px;
    }
}
.TransactionLane_mainContainer__2CI2k {
    position: relative;
    overflow: hidden;
}

.TransactionLane_preRTDNav__2lzU8 {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 4;
}

.TransactionLane_preRTD__83Nfk {
    padding: 10px;
    width: 100%;
    background-color: #f0f2f5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 60px;

}

.TransactionLane_preRTD__83Nfk .TransactionLane_RTDSection__3fF4r {
    margin: auto;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-right: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 10px;
    height: calc(100vh - 6rem);
    width: 100%;
    
}

.TransactionLane_preRTD__83Nfk .TransactionLane_DoraDocumentSection__srVep {
    background: white;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    height: calc(100vh - 6rem);
}

@media screen and (min-width: 551px) and (max-width: 850px) {
    
    .TransactionLane_preRTD__83Nfk {
        margin-top: 55px;
    }
}

@media screen and (max-width: 550px) {
    .TransactionLane_outerSectionScroll__1cTql {
        overflow-x: auto;
        overflow-y: hidden;
    }

    .TransactionLane_mainContainer__2CI2k .TransactionLane_preRTD__83Nfk {
        width: 150%;
        margin-top: 50px;
    }

    .TransactionLane_preRTD__83Nfk .TransactionLane_RTDSection__3fF4r {
        width: 100%;
    }

    .TransactionLane_preRTD__83Nfk .TransactionLane_DoraDocumentSection__srVep {
        width: 50%;
    }
}
.OIformWrapper textarea {
  width: 100%;
}
.OIformWrapper .grow-with-content {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}

.OIformWrapper .grow-with-content::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";
  /* This is how textarea text behaves */
  white-space: pre-wrap;
  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}

.OIformWrapper .grow-with-content > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;
  cursor: text;
  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.OIformWrapper .grow-with-content > textarea,
.OIformWrapper .grow-with-content::after {
  border: 0;
  padding: 0.25em;
  /* background-color: #f0f7fd;
  font-weight: bold;
  color: blue;
  font-size: inherit;
  font-family: inherit; */
  page-break-inside: avoid;
  break-inside: avoid;

  grid-area: 1 / 1 / 1 / 1;
}

.OIformWrapper .grow-with-content > textarea:not(.nm),
.OIformWrapper .grow-with-content:not(.nm)::after {
  /* margin-top: 0.5rem;
  margin-bottom: 0.5rem; */
  border: 1px solid black;
}

thead[class*="ant-table-thead"] tr th {
  background-color: hsl(211, 75%, 48%) !important;
  font-weight: 500 !important;
  color: white;
 
}

.emailModal {
  top: 30px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 60vh;
}

.emailModal .ant-modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.emailModal .ant-modal-header {
  flex-shrink: 0;
}

.emailModal .ant-modal-footer {
  flex-shrink: 0;
}

.emailModal .ant-modal-body {
  flex-grow: 1;
  overflow: auto;
}

.emailModal .ant-table {
  margin-bottom: 15px;
}

.emailModal .message-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid grey;
  padding: 10px;
}

.emailModal .message-container .ant-input-textarea {
  height: 100%;
  padding: 10px;
}

@media (min-width: 800px) {
  .emailModal .message-container {
    overflow: auto;
  }
  .emailModal {
    min-width: 600px;
    height: 80vh;
  }
}
@media (min-width: 1000px) {
  .emailModal {
    min-width: 800px;
    height: 90vh;
  }
}


.container {
  width: 100%;
  height: 100vh;
  top: 10%;
  left: 10%;
}

.sigContainer {
  width: 80%;
  height: 80%;
  margin: 0 auto;
  background-color: #fff;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.buttons {
  width: 100%;
  height: 30px;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}

#NDAFormContainer .page {
    background-color: white;
    position: relative;
    z-index: 0;
  }
  
  @media (min-width: 601px) {
   #NDAFormContainer .text {
      position: absolute;
      z-index: 11;
      opacity: inherit;
      white-space: nowrap;
      font-size: medium;
    }
  }
  @media (max-width: 600px) {
#NDAFormContainer .text {
      position: absolute;
      z-index: 11;
      opacity: inherit;
      white-space: nowrap;
      font-size: small;
    }
  }
  
  #NDAFormContainer .control {
    position: absolute;
    z-index: 13;
  }

  #NDAFormContainer .signaturealighnment {
    height: 55px;
  }
  #NDAFormContainer .signatureDivAlighnment {
    position: absolute;
    padding: 0 !important;
    display: grid;
    grid-template-columns: 1fr 1fr;
    left: 96.8px;
    top: -23.7px;
    width: 283px;
    z-index: 12;
    border: 1px solid #b6dfdc;
    background-color: #b6dfdc;
    color: rgb(117, 117, 117);
    height: 72px;
    border-radius: 7px;
    box-shadow: rgb(188 184 184) 0px 3px 6px;
  }
  #NDAFormContainer .signatureResureAlighnment {
    background: #95d3ce;
    color: darkblue;
  }

  



.BugListingAdmin_site_layout_sub_header_background__2pd87 {
  background: #fff;
}

.BugListingAdmin_site_layout_background__29JfQ {
  background: #fff;
  min-height: 100vh;
}

.ClientAuth_alignAdmin__1fpIt {
  background-image: url(https://i.pinimg.com/originals/bb/98/55/bb9855e4ce31410e9289453ddb447ceb.jpg);
  background-position: top center;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  max-height: 100%;
  background-repeat: no-repeat;
  background-color: #444;
  position: relative;
  overflow-x: hidden;
}

.ClientAuth_alignAdmin__1fpIt:after {
  content: "";
  background-color: #032345;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
}

.ClientAuth_custForm__3EghR {
  background-color: #fff;
  width: 550px;
  max-width: 90%;
  /* margin: 70px auto; */
  display: block;
  min-height: 300px;
  box-shadow: 0 0 8px #6c6969;
  /* padding: 40px 50px; */
  border-top: 8px solid #dbdfe3;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  position: absolute !important;
  opacity: 1.5;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ClientAuth_Loginlogo__3iXiv {
  width: 60px;
  margin: 10px auto;
  display: block;
}

.ClientAuth_custFormH1__m0Ez_ {
  font-size: 42px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #404b51;
  text-align: center;
  line-height: 55px;
  margin-bottom: 10px;
}

.ClientAuth_custFormH2__2Sj-n {
  font-size: 28px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #8e959a;
  text-align: center;
  line-height: 37px;
  margin-bottom: 10px;
}

.ClientAuth_center__32FVq {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}

.ClientAuth_btnPrimary__1qU5b {
  width: 60%;
  text-transform: uppercase;
  border-radius: 3px !important;
}

.planDetailsModalDiv {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.planDetailsModalDiv p:first-child {
  font-size: 18px;
  font-weight: 600;
  width: 170px;
}
.planDetailsModalDiv p:nth-child(2) {
  font-size: 16px;
  font-weight: 400;
}

.form_createHelpForm__2Z1fw .form_label__2InFM:hover {
    background-color: none;
    color: initial;
  }

.form_label__2InFM {
    display: block;
    padding: 5px 10px;
    cursor: pointer;
  }

 .form_inputtag__2iVGr[type="checkbox"] {
    margin-right: 5px;
    cursor: pointer;
  }

  /* Style the "Select All" label differently */
.form_inputtag__2iVGr[type="checkbox"]:first-child + label {
    font-weight: bold;
    color: #333;

  }

  /* Style selected options */
.form_label__2InFM input[type="checkbox"]:checked + label {
    background-color: #f0f0f0;
  }

  /* Hover effect for options */
.form_label__2InFM:hover {
    background-color: #e0e0e0;
  }

  .form_createHelpForm__2Z1fw .form_ant-form-item-label__2xHPY {
    margin-bottom: -9px;
  }

.form_createHelpForm__2Z1fw .form_ant-form-item-control-input__3iYDc {
    margin-bottom: -18px;
  }

  .createHelpForm .bold-label {
    font-weight: bold;
    color: grey;
  }

  .createHelpForm .inner-span{
    color:red !important;
    font-weight:"bolder";
    font-size:18px;
    margin-right: 5px;
  }

 .createHelpForm .ql-toolbar .ql-image {
    display: none;
  }

 .createHelpForm .imgIcon:hover{
     color:"lightblue"
  }

  /* hide scrollbar
 ::-webkit-scrollbar {
    display: none;
  } */

 .createHelpForm .imageIcon{
    position:relative;
    left:400px;
    top:85px;
  }

 .createHelpForm .imageIcon:hover{
    color: #096dd9;
  }

  /* create form css       */

  .createHelpForm .checkbox-select {
    position: relative;
    width: 200px; /* Adjust the width as needed */
    font-family: Arial, sans-serif;
  }

 .createHelpForm .select-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color:none;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

 .createHelpForm .selected-options {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

 .createHelpForm .toggle-icon {
    font-size: 12px;
  }

 .createHelpForm .options-dropdown {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: auto;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    z-index: 1;
  }

 .createHelpForm .flexdiv {
    display: flex;
    flex-direction: column;
  }

 .createHelpForm .label-bold{
    font-weight: bold;
  }

  /* tag css  */
 .createHelpForm .tag {
    display: inline-flex;
    align-items: center;
    margin: 4px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #e0e0e0;
    position: relative;
  }

 .createHelpForm .delete-button {
    display: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    margin-left: 4px;
  }

 .createHelpForm .tag:hover .delete-button {
    display: block;
    color: Black; /* Customize the delete button color on hover */
  }

  /* btn css */
 .createHelpForm .custom-button {
    background-color: #1890ff; /* Ant Design primary color */
    color: #fff; /* Text color */
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    /* font-size: 16px; */
    cursor: pointer;
    transition: background-color 0.3s ease;

    /* Hover state */
    &:hover {
      background-color: #40a9ff; /* Lighter shade on hover */
    }

    /* Active state */
    &:active {
      background-color: #096dd9; /* Slightly darker shade on click */
    }
  }

  /* Input container */
.createHelpForm .input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

/* Input field */
.createHelpForm .custom-input {
  width: 100%;
  padding: 4px 6px;
  border: 1px solid #d9d9d9; /* Ant Design input border color */
  /* border-radius: 4px; */
  outline: none;
  transition: border-color 0.3s ease;
  font-size: 14px;
  line-height: 1.5715;
  color: rgba(0, 0, 0, 0.65);
}

/* Input focus state */
.createHelpForm .custom-input:focus {
  border-color: #1890ff; /* Ant Design primary color on focus */
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

/* Placeholder text */
.createHelpForm .custom-input::placeholder {
  color: #bfbfbf; /* Ant Design input placeholder color */
}

/* Disabled input */
.createHelpForm .custom-input[disabled] {
  background-color: #f5f5f5; /* Ant Design disabled input background color */
  cursor: not-allowed;
}

/* Add some margin to the top for proper spacing */
.createHelpForm .input-container + .input-container {
  margin-top: 8px;
}





/* Styling for Custom Ant Design-like Select */
.createHelpForm .custom-select-container {
        width: 265px;
        position: relative;
        z-index: 2;

  }

.createHelpForm .custom-select {
    position: relative;
    width: 70%;
    padding: 4px 6px;
    border: 1px solid #d9d9d9; /* Ant Design input border color */
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    -webkit-user-select: none;
            user-select: none;
  }

  .createHelpForm .selected-option {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.65);
  }

  .createHelpForm .select-icon {
    font-size: 16px;
    color: #bfbfbf; /* Ant Design input placeholder color */
  }

  /* Dropdown options */
 .createHelpForm .options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; /* Ant Design input border color */
    border-top: none;
    border-radius: 0 0 4px 4px;
    max-height: 500px; /* Adjust the max height as needed */
    overflow-y: auto;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .createHelpForm .options {
    /* ... other properties ... */
    background-color: #ffffff !important;
    display: block;
}

 .createHelpForm  .option {
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

.createHelpForm .option:hover {
    background-color: #f0f0f0; /* Ant Design option hover background color */
  }

  .createHelpForm .option.selected {
    background-color: #1890ff; /* Ant Design primary color for selected option */
    color: #fff; /* Text color for selected option */
  }

  .createHelpForm .custom-button[disabled] {
    background-color: #f5f5f5; /* Background color for disabled state */
    color: #ccc; /* Text color for disabled state */
    border: 1px solid #d9d9d9; /* Border color for disabled state */
    cursor: not-allowed; /* Change cursor to not-allowed */
    pointer-events: none; /* Disable click events */
  }


table#planDetails,
table#paymentDetails {
    border-collapse: collapse;
}

table#planDetails td,
table#paymentDetails td {
    border: 1px solid silver;
    padding: 0 10px;
}

table#planDetails td.label,
table#paymentDetails td.label {
    text-align: left;
    white-space: nowrap;
}

table#planDetails td.value,
table#paymentDetails td.value {
    font-weight: bold;
    color: orange;
    word-break: break-all;
    /* 'break-word' */
}

#logs .headerDiv {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: aliceblue;
}

#logs .headerDiv .resetBtn {
    width: 100%;
    text-align: left;
}

#logs .headerDiv .titleDiv {
    width: 100%;
    text-align: center;
    font-size: large;
}

#logs .headerDiv .blankDiv {
    width: 100%;
    text-align: center;
}

@media print {
    .ShowEmailContent_noPrint__3mcWa {
        display: none;
        visibility: hidden;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
    }
}

.ShowEmailContent_EmailPageOuterContainer__BY2Oy {
    margin: 0;
    padding: 0;
    position: relative;
}

.ShowEmailContent_emailScreenContainer__3Jh0L {
    padding-top: 10vh;
    background-color: aliceblue;
}

.ShowEmailContent_outerBox__11uj4 {
    min-height: 90vh;
    width: 60%;
    background-color: white;
    margin: auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 1rem 2rem;
}

.ShowEmailContent_outerBox__11uj4 .ShowEmailContent_subject__2tMia {
    font-size: large;
    color: grey;
    font-weight: bold;
}

.ShowEmailContent_outerBox__11uj4 .ShowEmailContent_date__3SvpI {
    font-size: small;
    color: rgb(164, 161, 161);
    text-align: right;
}

.ShowEmailContent_outerBox__11uj4 table .ShowEmailContent_label__2bH9a {
    font-weight: bold;
    vertical-align: text-top;
}

.ShowEmailContent_outerBox__11uj4 table .ShowEmailContent_value__hwESv {
    padding-left: 10px;
}

.ShowEmailContent_outerBox__11uj4 .ShowEmailContent_emailBody__1IeAA {
    width: 100%;
    margin-top: 1rem;
}

.ShowEmailContent_outerBox__11uj4 .ShowEmailContent_emailAttachments__kvgbU {
    margin-top: 1rem;
}

/* To make image responsive */
.ShowEmailContent_outerBox__11uj4 .ShowEmailContent_emailBody__1IeAA img,
.ShowEmailContent_outerBox__11uj4 .ShowEmailContent_emailAttachments__kvgbU img {
    max-width: 100% !important;
}

@media screen and (min-width: 551px) and (max-width: 850px) {

    .ShowEmailContent_outerBox__11uj4 {
        width: 80%;
    }

    .ShowEmailContent_outerBox__11uj4 .ShowEmailContent_subject__2tMia {
        font-size: medium;
    }

    .ShowEmailContent_outerBox__11uj4 table .ShowEmailContent_label__2bH9a,
    .ShowEmailContent_outerBox__11uj4 table .ShowEmailContent_value__hwESv {
        font-size: small;
    }

    .ShowEmailContent_outerBox__11uj4 .ShowEmailContent_emailBody__1IeAA,
    .ShowEmailContent_outerBox__11uj4 .ShowEmailContent_emailAttachments__kvgbU {
        font-size: small;
    }
}

@media screen and (max-width: 550px) {

    .ShowEmailContent_emailScreenContainer__3Jh0L {
        padding-top: 7vh;
    }

    .ShowEmailContent_outerBox__11uj4 {
        width: 90%;
    }

    .ShowEmailContent_outerBox__11uj4 .ShowEmailContent_subject__2tMia {
        font-size: medium;
    }

    .ShowEmailContent_outerBox__11uj4 .ShowEmailContent_date__3SvpI {
        font-size: 10px;
    }

    .ShowEmailContent_outerBox__11uj4 table .ShowEmailContent_label__2bH9a,
    .ShowEmailContent_outerBox__11uj4 table .ShowEmailContent_value__hwESv {
        font-size: small;
    }

    .ShowEmailContent_outerBox__11uj4 .ShowEmailContent_emailBody__1IeAA,
    .ShowEmailContent_outerBox__11uj4 .ShowEmailContent_emailAttachments__kvgbU {
        font-size: small;
    }

}
@media print {
    .CommonNavbar_noPrint__UlIxT {
        display: none;
        visibility: hidden;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
    }
}

.CommonNavbar_topNavBar__3lQYO {
    width: 100%;
    height: 5vh;
    position: fixed;
    top: 0;
    background-color: #124E8F;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em;
    z-index: 1;
}

.CommonNavbar_topNavBar__3lQYO .CommonNavbar_logoContainer__ohaHz {
    width: 100%;
    text-align: left;
}

.CommonNavbar_topNavBar__3lQYO .CommonNavbar_logoContainer__ohaHz img {
    width: 80px;
    cursor: pointer;
}

.CommonNavbar_topNavBar__3lQYO .CommonNavbar_toolsContainer__2AgpM {
    width: 100%;
    display: flex;
}

.CommonNavbar_topNavBar__3lQYO .CommonNavbar_toolsContainer__2AgpM .CommonNavbar_dummyDiv__6cnnA,
.CommonNavbar_topNavBar__3lQYO .CommonNavbar_toolsContainer__2AgpM .CommonNavbar_printBtnContainer__ziFxD,
.CommonNavbar_topNavBar__3lQYO .CommonNavbar_toolsContainer__2AgpM .CommonNavbar_closeBtnContainer__32nlr {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: white;
}

.CommonNavbar_topNavBar__3lQYO .CommonNavbar_toolsContainer__2AgpM .CommonNavbar_closeBtnContainer__32nlr .CommonNavbar_closeBtn__10NBW,
.CommonNavbar_topNavBar__3lQYO .CommonNavbar_toolsContainer__2AgpM .CommonNavbar_dummyDiv__6cnnA .CommonNavbar_saveBtn__3iafA,
.CommonNavbar_topNavBar__3lQYO .CommonNavbar_toolsContainer__2AgpM .CommonNavbar_printBtnContainer__ziFxD .CommonNavbar_printBtn__PlHML {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

@media screen and (min-width: 551px) and (max-width: 850px) {

    .CommonNavbar_topNavBar__3lQYO .CommonNavbar_logoContainer__ohaHz img {
        width: 70px;
    }
}

@media screen and (max-width: 550px) {

    .CommonNavbar_topNavBar__3lQYO {
        height: 5vh;
        padding: 1.5em;
    }

    .CommonNavbar_topNavBar__3lQYO .CommonNavbar_logoContainer__ohaHz img {
        width: 55px;
    }

    .CommonNavbar_topNavBar__3lQYO .CommonNavbar_toolsContainer__2AgpM .CommonNavbar_closeBtnContainer__32nlr .CommonNavbar_closeBtn__10NBW span,
    .CommonNavbar_topNavBar__3lQYO .CommonNavbar_toolsContainer__2AgpM .CommonNavbar_dummyDiv__6cnnA .CommonNavbar_saveBtn__3iafA,
    .CommonNavbar_topNavBar__3lQYO .CommonNavbar_toolsContainer__2AgpM .CommonNavbar_printBtnContainer__ziFxD .CommonNavbar_printBtn__PlHML span {
        font-size: 10px;
    }
}
@media print {
    .ContactSheet_noPrint__WAdUX {
        display: none;
        visibility: hidden;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
    }

    .ContactSheet_contactSheetScreen__25MPT {
        overflow-y: scroll !important;
        height: auto !important;
    }

    .ContactSheet_contactSheetContainer__1rclO {
        margin-top: 0 !important;
    }

    .ContactSheet_headerContainer__102NR {
        margin-bottom: 10px !important;
    }

    .ContactSheet_contactSheetGridDesign__32-CV {
        border: none !important;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_propertySection__u5ki4 {
        border: 2px solid #124E8F;
        border-radius: 5px;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu {
        height: auto !important;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_gridScroll__3XTwc {
        /* padding: 0 !important; */
        display: grid !important;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
        grid-gap: 10px;
        gap: 10px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu::-webkit-scrollbar {
        width: 0 !important;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu #ContactSheet_BUYER_AGENT__3t5tv {
        margin-right: 0 !important;
    }
    
    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu #ContactSheet_SELLER_AGENT__2JPFf {
        margin-left: 0 !important;
    }
    
    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm {
        page-break-inside: avoid;
        padding-bottom: 10px !important;
    }
    
    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr table .ContactSheet_value__1WX-d {
        word-break: break-all;
    }

}

.ContactSheet_contactSheetScreen__25MPT {
    overflow: hidden;
}

.ContactSheet_contactSheetContainer__1rclO {
    width: 70%;
    margin: auto;
    /* height: 90vh; */
    margin-top: 9vh;
}

.ContactSheet_headerContainer__102NR {
    font-size: 20px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
}

.ContactSheet_headerContainer__102NR .ContactSheet_emailContactSheet__2V-1c {
    width: 100%;
    font-weight: bold;
    color: orange;
    font-size: 18px;
    text-align: left;
    display: flex;
}

.ContactSheet_headerContainer__102NR .ContactSheet_emailContactSheet__2V-1c:hover {
    color: rgb(221, 151, 20);
    cursor: pointer;
}

.ContactSheet_headerContainer__102NR .ContactSheet_title__32aH_ {
    width: 100%;
    text-align: center;
    color: #189EFF;
    font-weight: bold;
}

.ContactSheet_headerContainer__102NR .ContactSheet_topToolsSection__JFKo4 {
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
}

.ContactSheet_headerContainer__102NR .ContactSheet_topToolsSection__JFKo4 .ContactSheet_plusCircle__cagGg {
    width: 50%;
    font-size: 22px;
    text-align: right;
    fill: #085191;
    color: #085191;
}

.ContactSheet_contactSheetGridDesign__32-CV {
    width: 100%;
    border: 2px solid #124E8F;
    border-radius: 5px;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_propertySection__u5ki4 {
    width: 100%;
    font-weight: bold;
    font-size: 20px;
    border-bottom: 2px solid #124E8F;
    padding: 0 10px;

}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_propertySection__u5ki4 .ContactSheet_propertyAddress__MC_zj {
    text-align: center;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu {
    width: 100%;
    height: 75vh;
    overflow: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow-x: hidden;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu::-webkit-scrollbar {
    width: 5px;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_gridScroll__3XTwc {
    /* padding: 0 10px; */
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu #ContactSheet_BUYER_AGENT__3t5tv {
    margin-right: 10px;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu #ContactSheet_SELLER_AGENT__2JPFf {
    margin-left: 10px;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm {
    width: 100%;
    border: 2px solid silver;
    /* border: 2px solid #124E8F; */
    page-break-inside: avoid;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    /* height: fit-content; */
    padding-bottom: 10px;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm:hover {
    /* background-color: aliceblue; */
    background-color: rgb(255, 244, 225);
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_headerBox__1dZO_ {
    display: flex;
    align-items: center;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_headerBox__1dZO_ .ContactSheet_tools__3voVS {
    width: 15%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: right;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_headerBox__1dZO_ .ContactSheet_tools__3voVS .ContactSheet_editContact__2HDh_ {
    width: 50%;
    cursor: pointer;
    color: orange;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_headerBox__1dZO_ .ContactSheet_tools__3voVS .ContactSheet_editContact__2HDh_:hover {
    color: blue;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_headerBox__1dZO_ .ContactSheet_tools__3voVS .ContactSheet_deleteContact__3sY-L {
    width: 50%;
    cursor: pointer;
    color: #178DFA;
    text-align: center;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_headerBox__1dZO_ .ContactSheet_dummy__1datA {
    width: 15%;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_title__32aH_ {
    width: 70%;
    margin: auto;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    color: #124e8f;
    cursor: pointer;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr {
    padding: 5px;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr table {
    width: 100%;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr table td {
    border: none;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr .ContactSheet_brokerageLogo__AKEK6 {
    text-align: left;
    vertical-align: top;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr .ContactSheet_image__3PrFm {
    text-align: center !important;
    vertical-align: top;
    padding-bottom: 10px;
}

.ContactSheet_flexContainer__36scQ {
    display: flex;
}

.ContactSheet_flexContainer__36scQ > div {
    flex: 1 1;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr .ContactSheet_image__3PrFm img,
.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr .ContactSheet_brokerageLogo__AKEK6 img {
    max-width: 70px;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr .ContactSheet_image__3PrFm .ContactSheet_userAvatar__1P4gQ {
    width: 50px;
    height: 50px;
    background: #085191;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr table .ContactSheet_label__2q7Nl {
    font-weight: bold;
    color: #124e8f;
    width: 20%;
    vertical-align: baseline;
    cursor: pointer;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr table .ContactSheet_value__1WX-d {
    vertical-align: baseline;
    cursor: pointer;
    word-break: break-all;
}

/* .contactSheetGridDesign .contactGrid .detailsBox .fullDetails table .value:hover {
    color: #1890ff;
} */

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr table .ContactSheet_value__1WX-d.ContactSheet_fullName__3gV3R {
    font-weight: bold;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr table input[type="text"],
.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr table input[type="email"],
.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr table input[type="tel"] {
    /* background-color: #f0f7fd; */
    border: 1px solid silver;
    border-radius: 5px;
    color: blue;
    font-weight: bold;
    background-color: inherit;
    width: 90%;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_notesSection__wbjVM {
    cursor: pointer;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_notesSection__wbjVM .ContactSheet_noteLabel__22I0s {
    font-weight: bold;
    color: orange;
    padding-left: 5px;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_notesSection__wbjVM .ContactSheet_textareaNotes__U9nRJ .ContactSheet_growWithContent__1H029 {
    display: grid;
}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_notesSection__wbjVM .ContactSheet_textareaNotes__U9nRJ .ContactSheet_growWithContent__1H029::after {
    content: attr(data-replicated-value) " ";
    white-space: pre-wrap;
    visibility: hidden;
    grid-area: 1 / 1 / 1 / 1;
    page-break-inside: avoid;
    break-inside: avoid;

}

.ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_notesSection__wbjVM .ContactSheet_textareaNotes__U9nRJ .ContactSheet_growWithContent__1H029 textarea {
    font-weight: bold;
    /* background-color: #f0f7fd; */
    background-color: inherit;
    border: none;
    border-radius: 2px;
    color: blue;
    font-weight: bold;
    width: 100%;
    padding: 0 5px;
    resize: none;
    cursor: text;
    overflow: hidden;
    grid-area: 1 / 1 / 1 / 1;
    page-break-inside: avoid;
    break-inside: avoid;

}

/* CSS for AddOrUpdateContact component */
.ContactSheet_formContainer__1bRqc {
    max-height: 300px;
    overflow: auto;
}

.ContactSheet_formContainer__1bRqc::-webkit-scrollbar {
    width: 5px;
}

@media screen and (min-width: 871px) and (max-width: 1000px) {

    .ContactSheet_contactSheetContainer__1rclO {
        width: 85%;
    }

    .ContactSheet_headerContainer__102NR {
        font-size: 18px;
    }

    .ContactSheet_headerContainer__102NR .ContactSheet_emailContactSheet__2V-1c {
        font-size: 14px;
    }

    .ContactSheet_headerContainer__102NR .ContactSheet_topToolsSection__JFKo4 .ContactSheet_plusCircle__cagGg {
        font-size: 18px;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_propertySection__u5ki4 {
        font-size: 16px;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu::-webkit-scrollbar {
        width: 3px;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr table .ContactSheet_label__2q7Nl,
    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr table .ContactSheet_value__1WX-d {
        font-size: 14px;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_notesSection__wbjVM {
        font-size: 14px;
    }
}

@media screen and (min-width: 551px) and (max-width: 870px) {

    .ContactSheet_contactSheetContainer__1rclO {
        width: 100%;
        padding: 0 10px;
    }

    .ContactSheet_headerContainer__102NR {
        font-size: 16px;
    }

    .ContactSheet_headerContainer__102NR .ContactSheet_emailContactSheet__2V-1c {
        font-size: 12px;
    }

    .ContactSheet_headerContainer__102NR .ContactSheet_topToolsSection__JFKo4 .ContactSheet_plusCircle__cagGg {
        font-size: 16px;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_propertySection__u5ki4 {
        font-size: 14px;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu::-webkit-scrollbar {
        width: 3px;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_title__32aH_ {
        font-size: 14px;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr table .ContactSheet_label__2q7Nl,
    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr table .ContactSheet_value__1WX-d {
        font-size: 12px;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_notesSection__wbjVM {
        font-size: 12px;
    }
}

@media screen and (max-width: 551px) {
    .ContactSheet_contactSheetContainer__1rclO {
        width: 100%;
        padding: 0 10px;
        margin-top: 8vh;
    }

    .ContactSheet_headerContainer__102NR {
        font-size: 12px;
    }

    .ContactSheet_headerContainer__102NR .ContactSheet_emailContactSheet__2V-1c {
        font-size: 10px;
    }

    .ContactSheet_headerContainer__102NR .ContactSheet_topToolsSection__JFKo4 .ContactSheet_plusCircle__cagGg {
        font-size: 14px;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_propertySection__u5ki4 {
        font-size: 12px;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu {
        flex-direction: column;
        grid-gap: 5px;
        gap: 5px;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu::-webkit-scrollbar {
        width: 2px;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_gridScroll__3XTwc {
        padding: 0 5px;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 5px;
        gap: 5px;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu #ContactSheet_BUYER_AGENT__3t5tv {
        margin-right: 0px;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu #ContactSheet_SELLER_AGENT__2JPFf {
        margin-left: 0px;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_title__32aH_ {
        font-size: 12px;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr table .ContactSheet_label__2q7Nl,
    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_contactGrid__sB5Fu .ContactSheet_detailsBox__2MCNm .ContactSheet_fullDetails__cPVXr table .ContactSheet_value__1WX-d {
        font-size: 12px;
    }

    .ContactSheet_contactSheetGridDesign__32-CV .ContactSheet_notesSection__wbjVM {
        font-size: 12px;
    }
}
@media print {
    .ant-typography-copy {
        display: none;
        visibility: hidden;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
    }
    .special-label,
    .flag-dropdown {
        display: none;
        visibility: hidden;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
    }
}

.SendContactSheet_outerContainer__3wsFm {
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

.SendContactSheet_outerContainer__3wsFm .SendContactSheet_selectEmails__1TTWm {
    /* height: 230px; */
    height: 175px;
    border: 1px solid silver;
    /* border-radius: 5px; */
    padding: 5px;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
}

.SendContactSheet_outerContainer__3wsFm .SendContactSheet_selectEmails__1TTWm .SendContactSheet_emailAddingOptionsContainer__3lrj_ {
    display: flex;
    justify-content: space-between;
}

.SendContactSheet_outerContainer__3wsFm .SendContactSheet_selectEmails__1TTWm .SendContactSheet_emailAddingOptionsContainer__3lrj_ .SendContactSheet_manualAddEmailBox__3KCL5 {
    width: 95%;
}

.SendContactSheet_outerContainer__3wsFm .SendContactSheet_selectEmails__1TTWm .SendContactSheet_emailAddingOptionsContainer__3lrj_ .SendContactSheet_manualAddEmailBox__3KCL5 .SendContactSheet_formInnerContainer__1RwRd {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.SendContactSheet_outerContainer__3wsFm .SendContactSheet_selectEmails__1TTWm .SendContactSheet_emailAddingOptionsContainer__3lrj_ .SendContactSheet_manualAddEmailBox__3KCL5 .SendContactSheet_formInnerContainer__1RwRd .SendContactSheet_formLabel__3460h {
    font-weight: bold;
}

.SendContactSheet_outerContainer__3wsFm .SendContactSheet_selectEmails__1TTWm .SendContactSheet_emailAddingOptionsContainer__3lrj_ .SendContactSheet_openClientListBtn__6T7Zv {
    text-align: center;
    width: 5%;
    font-size: 20px;
    fill: #085191;
    color: #085191;
}

.SendContactSheet_outerContainer__3wsFm .SendContactSheet_selectEmails__1TTWm .SendContactSheet_showSelectedEmails__2RsAB {
    overflow: auto;
    height: 125px;
}

.SendContactSheet_outerContainer__3wsFm .SendContactSheet_selectEmails__1TTWm .SendContactSheet_showSelectedEmails__2RsAB::-webkit-scrollbar {
    width: 5px;
}

.SendContactSheet_outerContainer__3wsFm .SendContactSheet_selectEmails__1TTWm .SendContactSheet_showSelectedEmails__2RsAB a {
    color: blue;
}

.SendContactSheet_outerContainer__3wsFm .SendContactSheet_emailContents__yYw06 {
    width: 100%;
}

.SendContactSheet_outerContainer__3wsFm .SendContactSheet_emailContents__yYw06 .SendContactSheet_emailSpace__3-aca {
    /* outline: 1px solid silver; */
    /* border-radius: 5px; */
    min-height: 175px;
}

.SendContactSheet_outerContainer__3wsFm .SendContactSheet_emailContents__yYw06 .SendContactSheet_emailSpace__3-aca .SendContactSheet_reactQuillComp__2Q3DR {
    height: 130px;
}

.SendContactSheet_outerContainer__3wsFm .SendContactSheet_emailContents__yYw06 .SendContactSheet_emailSpace__3-aca textarea {
    width: 100%;
    border: none;
    /* height: 100px; */
    height: 175px;
    padding: 5px;
    resize: none;
}

.SendContactSheet_outerContainer__3wsFm .SendContactSheet_emailContents__yYw06 .SendContactSheet_emailSpace__3-aca textarea::-webkit-scrollbar {
    width: 5px;
}

.SendContactSheet_outerContainer__3wsFm .SendContactSheet_emailContents__yYw06 .SendContactSheet_emailSpace__3-aca textarea:focus {
    outline: none;
}

@media screen and (max-width: 551px) {
    
    .SendContactSheet_outerContainer__3wsFm .SendContactSheet_selectEmails__1TTWm .SendContactSheet_emailAddingOptionsContainer__3lrj_ .SendContactSheet_manualAddEmailBox__3KCL5 {
        font-size: small;
    }

    .SendContactSheet_outerContainer__3wsFm .SendContactSheet_selectEmails__1TTWm .SendContactSheet_emailAddingOptionsContainer__3lrj_ .SendContactSheet_manualAddEmailBox__3KCL5 .SendContactSheet_formLabel__3460h {
        font-size: small;
    }

    .SendContactSheet_outerContainer__3wsFm .SendContactSheet_selectEmails__1TTWm .SendContactSheet_emailAddingOptionsContainer__3lrj_ .SendContactSheet_manualAddEmailBox__3KCL5 input {
        font-size: small;
    }

    .SendContactSheet_outerContainer__3wsFm .SendContactSheet_selectEmails__1TTWm .SendContactSheet_emailAddingOptionsContainer__3lrj_ .SendContactSheet_manualAddEmailBox__3KCL5 button {
        font-size: small;
    }

    .SendContactSheet_outerContainer__3wsFm .SendContactSheet_selectEmails__1TTWm .SendContactSheet_emailAddingOptionsContainer__3lrj_ .SendContactSheet_openClientListBtn__6T7Zv {
        font-size: 16px;
    }

    .SendContactSheet_outerContainer__3wsFm .SendContactSheet_selectEmails__1TTWm .SendContactSheet_showSelectedEmails__2RsAB a {
        font-size: small;
    }

    .SendContactSheet_emailContents__yYw06 {
        font-size: small;
    }

}

/* .phone-input .react-phone-input {
    width: 223px !important;
  }
  .form-control {
    width: 223px !important;
    height: 34px;
  }
  .react-tel-input {
    width: 200px !important;
  } */

