table#planDetails,
table#paymentDetails {
    border-collapse: collapse;
}

table#planDetails td,
table#paymentDetails td {
    border: 1px solid silver;
    padding: 0 10px;
}

table#planDetails td.label,
table#paymentDetails td.label {
    text-align: left;
    white-space: nowrap;
}

table#planDetails td.value,
table#paymentDetails td.value {
    font-weight: bold;
    color: orange;
    word-break: break-all;
    /* 'break-word' */
}

#logs .headerDiv {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: aliceblue;
}

#logs .headerDiv .resetBtn {
    width: 100%;
    text-align: left;
}

#logs .headerDiv .titleDiv {
    width: 100%;
    text-align: center;
    font-size: large;
}

#logs .headerDiv .blankDiv {
    width: 100%;
    text-align: center;
}
